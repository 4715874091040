<template>
    <div>
      <div>
        <b>Is this a Conforming/Seismic Curb?</b><br>
        <label><input type="radio" v-model="conforming" value="1" @change="$emit('update:val', conforming)"> Yes</label>
        <label><input type="radio" v-model="conforming" value="0" @change="$emit('update:val', conforming)"> No</label>
      </div>
        <div v-if="conforming_type_id" class="badge badge-primary"> Conforming {{ conforming_type }}</div>
      <div v-show="conforming">
        <div class="form-group" >
          <label>Conforming Stamp Location</label>
          <select class="form-control"  v-model="conforming_location_id" @change="get_conforming_stamp_types()">
            <option v-for="[id, type] in conformingStampLocations" v-bind:key="id" :value="id">
              {{ type }}
            </option>
          </select>
        </div>
        <div class="spacing_btm" v-show="conforming_location_id === 2">
          <b>Is this an OSHPD (Isolation Spring Restraints) Curb?</b><br>
          <label><input type="radio" v-model="is_oshpd" v-bind:value="1" @change="$emit('update', conforming_type_id, is_oshpd, conforming_location_id)"> Yes</label>
          <label><input type="radio" v-model="is_oshpd" v-bind:value="0" @change="$emit('update', conforming_type_id, is_oshpd, conforming_location_id)" checked> No</label>
        </div>
        <div class="form-group" v-show="conforming_location_id">
          <label>Code Year</label>
          <select class="form-control" v-model="conforming_type_id"  @change="$emit('update', conforming_type_id, is_oshpd, conforming_location_id)">
            <option v-for="[id, year] in conformingStampCodeYears" v-bind:key="id" :value="id">
              {{ year }}
            </option>
          </select>
        </div>
      </div>
    </div>
</template>

<script>
import conforming from "@/cache/conforming_stamp.cache";
import {store} from "@/store/BusinessObject.store";

export default {
    name: "Conforming",
    props: ['val', 'conformingId', 'label', 'isOshpd'],
    data() {
        return {
          conforming: 0,
          conforming_type_id: 0,
          conforming_type: "",
          is_oshpd: 0,
          editConformingType: false,
          conformingStampCodeYears: [
            [0, 'Options Not Available']
          ],
          conformingStampLocations: [],
          conforming_location_id: '',
          cache: {conforming}
        }
    },
  computed: {
    showConformingType: function() {
      if (!this.editCurb.curb_id) {
        return (this.curb.conforming);
      } else if (this.editCurb.conforming) {
        return this.editConformingType;
      } else {
        return this.curb.conforming
      }
    }
  },
  methods: {
    get_conforming_stamp_types: function() {
      let options = {
        'params[conforming_location_id]': this.conforming_location_id
      }
      store.customApi('menu_builder/conforming_stamp_code_years', options).then(
          res => {
            this.conformingStampCodeYears = res;
            this.conforming_type_id = res[0][0];
            this.update()});
      },
    update: function() {
      this.$emit('update', this.conforming_type_id, this.is_oshpd, this.conforming_location_id);
    },
    get_conforming_stamp_locations: function () {
      store.customApi('menu_builder/conforming_stamp_locations').then(
          res => {
            this.conformingStampLocations = res;
          }
      )
    }

  },
    created() {
        this.conforming = this.val ? 1 : 0;
        this.conforming_type_id = this.conformingId ;
        this.conforming_type = this.label;
        this.is_oshpd = this.isOshpd;
        this.get_conforming_stamp_locations();
    },
}
</script>

<style scoped>

</style>