<template>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
        <label>Part</label>
        <Typeahead ref="customPart" :cdi_type="cdiType" @objectSelected="(data) => $emit('update', data)" />
    </div>
</template>

<script>
import Typeahead from '@/components/utils/typeaheads/Typeahead';
import curbTypeCache from  '@/cache/curbType.cache.js';
export default {
    name: "Part",
    props: ['val', 'curbTypeID'],
    components: {
        Typeahead,
    },
    data() {
        return {
            cache: {
                curbTypeCache
            },
        }
    },
    computed: {
        cdiType: function() {
            return this.curbTypeID == this.cache.curbTypeCache.MISCELLANEOUS ? 'misc_part' : 'custom_part';
        }
    },
    mounted() {
        this.$refs.customPart.$data.object = this.val;
    }
}
</script>

<style scoped>

</style>