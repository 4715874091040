<template>
    <div>
        <div class="line_drawings">
            <span v-if="!jobCosting" class="btn-link line_drawings pointer" @click="getJobCosting">Job Costing</span>
            <span v-if="!jobCosting" class='pull-right'>{{ status }}</span>
            <table v-else class="table">
                <tbody>
                <tr>
                    <th colspan="5">Labor Costs <span @click="changeView('newTask')" class='glyphicon glyphicon-plus-sign cdi_link' style=""></span></th>
                    <th class="number"><b>{{ jobCosting.jobcost.labor_total }}</b></th>
                </tr>

                <tr v-for="(labor, index) in jobCosting.jobcost.labor" :key="index" >
                    <td>
                        <span v-show="permission && !locked && labor.complete" :class="`glyphicon cdi_link glyphicon-share-alt mirror`" @click='uncompleteTask(labor.tracker_task_id)'></span>&nbsp;
                        <span v-show="permission && !locked" :class="`glyphicon cdi_link glyphicon-trash`" @click="deleteConfirmation('deleteTask', labor.tracker_task_id)"></span>
                    </td>
                    <td>{{ labor.task }}</td>
                    <td>{{ labor.employee }}</td>
                    <td>{{ labor.duration }} mins</td>
                    <td>{{ labor.taskdate }}</td>
                    <td class="number">{{ labor.total }}</td>
                </tr>

                <tr>
                    <th colspan="5">Material Costs</th>
                    <th class="number"><b>{{ jobCosting.jobcost.material_total }}</b></th>
                </tr>

                <tr v-for='(material, index) in jobCosting.jobcost.material' :key='`a${index}`' >
                    <td colspan="5"> {{ material.material }}<span v-if='material.amount'>: {{ material.amount}} @ {{ material.cost}} {{ material.unit_size}} </span></td>
                    <td class="number">{{ material.total }}</td>
                </tr>

                <tr>
                    <th colspan="5">Misc Costs <span @click="changeView('newMisc')" class="glyphicon glyphicon-plus-sign cdi_link"></span></th>
                    <th class="number"><b>{{ jobCosting.jobcost.misc_total }}</b></th>
                </tr>
                <tr v-for='(misc, index) in jobCosting.jobcost.misc' :key='`b${index}`'>
                    <td>
                        <span class="glyphicon glyphicon-trash cdi_link" @click="deleteConfirmation('deleteMisc', misc.tracker_misc_id)"></span>
                    </td>
                    <td colspan="4">{{ misc.misc_desc }}</td>
                    <td class="number">{{ misc.misc_cost }}</td>
                </tr>
                <tr>
                    <th colspan="5">Shipping Costs</th>
                    <th class="number"><b>{{ jobCosting.jobcost.shipping_total }}</b></th>
                </tr>
                <tr v-for='(shipping, index) in jobCosting.jobcost.shipping' :key='`c${index}`' >
                    <td></td>
                    <td></td>
                    <td>{{ shipping.employee }}</td>
                    <td></td>
                    <td>{{ shipping.shipping_add_date }}</td>
                    <td class="number">{{ shipping.shipping_cost }}</td>
                </tr>
                <tr>
                    <td colspan="5">Total Costs</td>
                    <td class="number"><b>{{ jobCosting.jobcost.total }}</b></td>
                </tr>
                </tbody>
            </table>
        </div>

        <ConfirmBox
            v-if="confirmBox.show"
            :heading="'Delete'"
            :body="'Delete Job Costing?'"
            @close="confirmedDelete"
        />
    </div>
</template>

<script>

import { store } from '@/store/BusinessObject.store';
import ConfirmBox from "@/components/utils/ConfirmBox";

export default {
    name: 'JobCosting',
    props: ['trackerInvoiceId', 'locked', 'permission', 'props', 'status'],
    components: {ConfirmBox},
    data() {
        return {
            showAddJcButtons: false,
            showAddMiscRow: false,
            showAddTaskRow: false,
            showJcTable: false,
            jobCosting: false,
            confirmBox: {
                show: false,
                function: '',
                target: 0
            }
        }
    },
    methods: {
        changeView: function(view) {
            this.$emit('changeView', {data: this.jobCosting, view: view});
        },
        getJobCosting: function() {
            store.customApi(`tracker_curb/to_json_jobcosting/${this.trackerInvoiceId}`, {}).then( (data) => {
                this.jobCosting = data;
            });
        },
        uncompleteTask: function(id) {
            store.customApi(`tracker_curb/uncomplete_task/${ this.trackerInvoiceId }`, {
                'params' : id
            }).then( (data) => {
                this.jobCosting = data;
            })
        },
        deleteTask: function() {
            store.customApi(`tracker_curb/delete_task/${ this.trackerInvoiceId }`, {
                'params' : this.confirmBox.target
            }).then( (data) => {
                this.jobCosting = data;
            })
        },
        deleteMisc: function() {
            store.customApi(`tracker_curb/delete_misc/${ this.trackerInvoiceId }`, {
                'params' : this.confirmBox.target
            }).then( (data) => {
                this.jobCosting = data;
            });
        },
        deleteConfirmation: function(fn, id) {
            this.confirmBox.function = fn;
            this.confirmBox.target = id;
            this.confirmBox.show = true;
        },
        confirmedDelete: function(confirmed) {
            if (confirmed) {
                if (this.confirmBox.function === 'deleteMisc') {
                    this.deleteMisc();
                } else if (this.confirmBox.function === 'deleteTask') {
                    this.deleteTask()
                }
            }
            this.resetConfirmBox();
        },
        resetConfirmBox: function() {
            this.confirmBox.function = '';
            this.confirmBox.show = false;
            this.confirmBox.target = 0;
        }
    }
}

</script>