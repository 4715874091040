<template>
  <div class="col-xs-6 form-group">
    <label>{{ label }}</label>
    <select v-model="top_offset_id" class="form-control" @change="$emit('update:val', top_offset_id)">
      <option v-for="top_offset in top_offsets" :key="top_offset[0]" :value="top_offset[0]">{{ top_offset[1] }}</option>
    </select>
  </div>
</template>

<script>
import curbTypeCache from  '@/cache/curbType.cache.js';
export default {
  name: "TopOffset",
  props: ['label', 'val', 'topOffsetID'],
  data() {
    return {
      top_offset_id: 0,
      cache: {
        curbTypeCache
      },
    }
  },
  components: {},
  computed: {
    top_offsets: function() {
      return this.cache.curbTypeCache.TOP_OFFSET;
    },
  },
  mounted() {
    this.$refs.model.$data.object = this.val;
  },
  created() {
    this.top_offset_id = this.val;
  },
}
</script>

<style scoped>

</style>