<template>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
        <label>Diameter</label>
        <select v-model="diameter" class="form-control" @change="$emit('update:val', diameter)">
            <option v-for="diameterOption in cache.curbTypeCache.BURGLAR_REBAR" :key="diameterOption[0]" :value="diameterOption[0]">{{ diameterOption[1] }}</option>
        </select>
    </div>
</template>

<script>
import curbTypeCache from  '@/cache/curbType.cache.js';
export default {
    name: "Diameter",
    props: ['val'],
    data() {
        return {
            diameter: 0,
            cache: {
                curbTypeCache
            },
        }
    },
    computed: {
    },
    created() {
        this.diameter = this.val;
    },
}
</script>

<style scoped>

</style>