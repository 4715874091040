<template>
    <div v-if="$appStore.hasRole([$cdiVars.CDI_ROLE_ACCOUNTING, $cdiVars.CDI_ROLE_LEADERSHIP])"
         class="panel panel-default spacing_top">
        <div class="panel-heading"><strong>Discounts</strong></div>
        <form @submit.prevent="save">
            <div class="row panel-body details_container">
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <label>Discount Reason</label>
                    <select class="form-control" v-model="discount.discount_definition_id">
                        <option v-for="[id, type] in cache.discountCache.REASONS" v-bind:key="id" :value="id">
                            {{ type }}
                        </option>
                    </select>
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <label>Discount Amount ($)</label>
                    <input type="text" @click="clearField('dollars')" v-model="discount.discount_amount_dollars"
                           class="form-control">
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <label>Discount Amount (%)</label>
                    <input type="text" @click="clearField('pct')" v-model="discount.discount_amount_pct"
                           class="form-control">
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <button class="cdi_button form-control">Save Discount</button>
                </div>
            </div>
        </form>
        <div class="row panel-body details_container">

        </div>
    </div>
</template>

<script>
import discountCache from "@/cache/discount.cache";

export default {
    name: "TotalDiscount",
    props: ['invoice'],
    components: {},
    data() {
        return {
            cache: {
                discountCache
            },
            discount: {
                discount_definition_id: '',
                discount_amount_dollars: '',
                discount_amount_pct: '',

            }
        }
    },
    methods: {
        save: function () {
            this.$emit('saveTotalDiscount', this.discount);
        },
        clearField: function (field) {
            if (field === 'dollars') {
                this.discount.discount_amount_pct = '';
            }

            if (field === 'pct') {
                this.discount.discount_amount_dollars = '';
            }
        },
    }

}
</script>

<style scoped>

</style>