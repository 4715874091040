<template>
    <div>
        <div v-if="id">
            <h1>{{ 'Order ' + state.object.data.tracker_id }}</h1>
            <h4 class="title_subhead">
                <span class='spacing_right'>Quote {{ state.object.data.quote_id }}</span>
                <span>Ship {{ state.object.data.scheduled_ship }}</span>
            </h4>

            <!--      ALERTS AND MESSAGES      -->
            <div v-if="notifications.length > 0" class='alert' :class="alertClass">
                <span v-for="(message, index) in notifications" v-bind:key="index">{{ message }}<br /></span>
            </div>
            <Notes :customerNotes="state.object.data.user_notes" :companyNotes="state.object.data.company_notes" />
            <div class="alert alert-info" v-if='state.object.data.remake'>
                <label>Remake</label> {{ state.object.data.remake }} of Order <router-link :to='`/tracker/${state.object.data.remake_tracker_id}`'  target='_blank'>{{ state.object.data.remake_tracker_id }}</router-link>
            </div>
            <div class="alert alert-info" v-if='state.object.data.tracker_group'>
                <span v-if="state.object.data.tracker_group_type_3"><label>Item Batch:</label>
                    <span v-for="(tracker) in state.object.data.tracker_group_type_3" v-bind:key="tracker">
                        <router-link :to='`/tracker/${tracker}`'  target='_blank'>  {{ tracker }}  </router-link>
                    </span>
                </span>
                <br />
                <span v-if="state.object.data.tracker_group_type_2"><label>Shipping:</label>
                    <span v-for="(tracker) in state.object.data.tracker_group_type_2" v-bind:key="tracker">
                        <router-link :to='`/tracker/${tracker}`'  target='_blank'>  {{ tracker }}  </router-link>
                    </span>
                </span>
            </div>
            <div class="alert alert-info" v-show='state.object.data.hold'>
                This order is on hold
            </div>

            <!--      DETAILS      -->
            <div v-show="isCurrentView('details')" class="btn-group btn_grp_responsive spacing_btm">
                <input class="btn btn-default" type="button" :value="(state.object.data.canceled) ? 'Uncancel' : 'Cancel'" @click="cancelToggle">
                <div class="btn-group" >
                    <button class="btn btn-default dropdown-toggle"  v-click-outside="handleOutsideClick" @click='togglePaperwork'>Create PDF <span class="caret"></span></button>
                    <ul class="dropdown-menu" :class="(showPaperwork) ? 'cdi_show' : 'cdi_hide'">
                        <li><a @click="trackerPaperwork('cover_sheets')" class="cdi_link">Cover Sheets</a></li>
                        <li><a @click="trackerPaperwork('shipping')" class="cdi_link">Shipping Paperwork</a></li>
                        <li><a @click="trackerPaperwork('bill_ladening')" class="cdi_link">Bill of Lading</a></li>
                        <li><a @click="trackerPaperwork('curb_stickers')" class="cdi_link">Item Stickers</a></li>
                        <li><a @click="trackerPaperwork('curb_stickers_nomenclature')" class="cdi_link">Item Bar Codes (2x4)</a></li>
                        <li><a @click="trackerPaperwork('turret_stickers')" class="cdi_link">Turret Stickers</a></li>
                        <li><a @click="trackerPaperwork('shipping_labels')" class="cdi_link">Shipping Labels</a></li>
                        <li><a @click="trackerPaperwork('florida_noa')" class="cdi_link">Florida NOA</a></li>
                        <li><a @click="trackerPaperwork('verification_drawings')" class="cdi_link">Verification Drawings</a></li>
                    </ul>
                </div>
                <input :disabled="state.object.data.canceled == 0 ? false : true" class="btn btn-default" type="button" @click="createOrderConfirmation" value="Email Order Details">
            </div>

            <!--      NOTEBOOK      -->
            <div v-show="isCurrentView('notebook')">
                <TrackerNotebook />
            </div>

            <!--      MAIN PAGE CONTENT      -->
            <div v-show="isCurrentView('details')">
                <Details :data="state.object.data" />
                <UserCcs />
                <OrderTasks  />
            </div>

            <Invoice ref="invoiceComponent" :creditCardLock="creditCardLock"
                 v-show="isCurrentView('invoice')"
            />

            <Remake v-show="isCurrentView('remake')" :trackerId='state.object.data.tracker_id' />

            <!--      SHIPPING COMPONENTS      -->
            <Shipping @refreshSkid='refreshSkid' :creditCardLock="creditCardLock" :customs="customs" v-show="isCurrentView('shipping')" />
            <Skid :skids='skids' v-show="isCurrentView('shipping')" @refreshSkid='refreshSkid' />

            <!--      HISTORY      -->
            <History v-show="isCurrentView('history')" />

            <Items v-show="isCurrentView('item')"
                   :creditCardLock="creditCardLock"
                   :curbs='this.curbs'
                   @refreshCurbs="getCurbData"
                   @changeView='changeView'
            />

            <!--    ITEM VIEWS    -->
            <AddItem v-if="isCurrentView('add-item')" :curb="addEditCurb" />
            <Estimator v-if="activeInvoiceCurb && isCurrentView('estimator')"
                       :businessObject="'tracker_curb'"
                       :curb="activeInvoiceCurb"
                       :editable="editable"
                       :creditCardLock="creditCardLock"
                       @changeView="state.currentView = 'details'"
            />
            <!--    ITEM -> JOB COSTING VIEWS    -->
            <NewTask
                v-if="activeInvoiceCurb && isCurrentView('newTask')"
                :curb='activeInvoiceCurb'
                @close="state.currentView = 'item'"
            />
            <NewMiscCost
                v-if="isCurrentView('newMisc') && activeInvoiceCurb"
                :curb='activeInvoiceCurb'
                @close="state.currentView = 'item'"
            />
        </div>

        <Search v-else />

        <!--      POP-UP WINDOWS       -->
        <ConfirmBox
            v-if="showConfirm"
            :heading="confirmHeader"
            :body="confirmBody"
            @close="confirmBoxHandler"
        />
        <TextareaBox
            v-show="showConfirmEmail"
            :heading="'Order Email'"
            :body="'Email customer a copy of their order?'"
            @confirm="sendOrderConfirmation"
            :fieldLabel="'Notes:'"
            :placeholder="'Add your notes here.'"
        />

    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import { trackerStore } from './Tracker.store.js';
import Search from '@/components/businessObjects/utils/Search';
import Details from './Details';
import History from '@/components/businessObjects/utils/History';
import ConfirmBox from '@/components/utils/ConfirmBox';
import TextareaBox from '@/components/utils/TextareaBox';
import UserCcs from '@/components/businessObjects/utils/UserCcs';
import Invoice from './Invoice';
import Shipping from "./Shipping";
import Skid from "./Skid";
import OrderTasks from './OrderTasks';
import Notes from './Notes';
import Remake from './Remake';
import TrackerNotebook from "@/components/businessObjects/tracker/TrackerNotebook";
import Items from './Items';
import Estimator from "@/components/businessObjects/utils/Estimator";
import NewMiscCost from "@/components/businessObjects/tracker/NewMiscCost";
import NewTask from "@/components/businessObjects/tracker/NewTask";
import AddItem from "@/components/businessObjects/tracker/AddItem";
import clickOutside from "@/directives/clickOutside";

export default {
    name: 'Container',
    data() {
        return {
            state: store.state,
            trackerState: trackerStore.state,
            showConfirm: false,
            showPaperwork: false,
            showConfirmEmail: false,
            confirmHeader: '',
            confirmBody: '',
            confirmAction: '',
            skids: {},
            customs: {
                canada: false,
                customs_bill_to: ''
            },
            curbs: {},
            activeInvoiceCurb: 0,
            addEditCurb: {},
            remake: false,
            ordersNumbers: {},
        }
    },
    props: ['id'],
    components: {
        TrackerNotebook,
        Skid,
        NewTask,
        NewMiscCost,
        Search,
        Details,
        History,
        ConfirmBox,
        TextareaBox,
        UserCcs,
        OrderTasks,
        Invoice,
        Shipping,
        Notes,
        Remake,
        Items,
        Estimator,
        AddItem
    },
    created() {
        store.initialize(this.trackerState);
        this.load();
    },
    methods: {
        load: function() {
            if (this.id){
                store.load(this.id)
                    .then(() => this.$appStore.setTitle([`${this.state.title} ${this.state.object.data.tracker_id}`]));
            }
        },
        isCurrentView: function(view) {
            return view === this.state.currentView;
        },
        togglePaperwork: function() {
            this.showPaperwork = !this.showPaperwork;
        },
        resetConfirmBox: function() {
            this.confirmHeader = '';
            this.confirmBody = '';
            this.confirmAction = 'cancel';
            this.showConfirm = false;
        },
        cancelToggle: function() {
            if (this.state.object.data.canceled) {
                this.setUncancelConfirm();
            } else {
                this.setCancelConfirm();
            }
        },
        setUncancelConfirm: function() {
            this.confirmHeader = 'Uncancel Order?';
            this.confirmBody = 'Would you like to uncancel this order?';
            this.confirmAction = 'uncancel';
            this.showConfirm = true;
        },
        setCancelConfirm: function() {
            this.confirmHeader = 'Cancel Order';
            this.confirmBody = 'Cancel Order?';
            this.confirmAction = 'cancel';
            this.showConfirm = true;
        },
        confirmBoxHandler: function(confirmed) {
            if (confirmed) {
                if (this.confirmAction === 'uncancel') {
                    store.api('uncancel_tracker', '', 'object.data');
                } else if (this.confirmAction === 'cancel') {
                    store.api('cancel_tracker', '', 'object.data');
                }
            }
            this.resetConfirmBox();
        },
        trackerRemake: function() {
            this.state.currentView = 'remake';
        },
        trackerPaperwork: function(document) {
            window.open(`${this.$cdiVars.API_URL}/tracker_pdf/${document}/${this.state.object.data.tracker_id}?params[seed]=${Math.random()}`, '_blank');
        },
        createOrderConfirmation: function() {
            this.showConfirmEmail = true;
        },
        sendOrderConfirmation: function(modalResponse) {
            if (modalResponse.confirmed) {
                store.api('send_order_confirmation_email', {'params[confirm_box_notes]': modalResponse.val});
            }
            this.showConfirmEmail = false;
        },
        refreshSkid: function() {
            this.skids = this.state.skids;
        },
        setNotes: function () {
            store.api('to_json_notebook').then(data => this.notes = data);
        },
        setCurbEditable: function(curb) {
            curb.editable = curb.permissions.edit;

            if (curb.status.length > 0) {
                curb.editable = false;
                curb.editable_warning_text = 'Only the notes field can be edited on this estimator. A new estimator cannot be created after Engineering is completed.';
            }

            if (this.state.object.data.locked) {
                curb.editable = false;
                curb.editable_warning_text = false;
            }
        },
        getCurbData:function () {
            store.api('to_json_curbs', {}).then( data => {
                for (let i = 0; i < data.curbs.length; i++) {
                    data.curbs[i].showBarcode = false;
                    this.setCurbEditable(data.curbs[i]);
                    data.curbs[i].showLineData = (data.curbs[i].line_master) ? true : false;
                }
                this.curbs = data.curbs
            });
        },
        changeView(data) {
            this.activeInvoiceCurb = data.data;
            this.state.currentView = data.view;
            if ( data.view === 'estimator' && data.data.editable_warning_text) {
                this.$appStore.warningBox(data.data.editable_warning_text)
            }else if (data.view === 'add-item') {
                this.addEditCurb = data.curb;
            }
        },
        handleOutsideClick: function () {
            this.showPaperwork = false
        },
    },
    computed: {
        creditCardLock: function() {
            return this.state.object.data.cc_required > 0;
        },
        currentView: function() {
            return this.state.currentView;
        },
        showRemake: function() {
            return this.$appStore.hasRole([
                this.$cdiVars.CDI_ROLE_ACCOUNTING,
                this.$cdiVars.CDI_ROLE_SALES
            ]);
        },
        notifications: function() {
            let notifications = [];
            if (this.state.object.data.canceled) {
                notifications.push('This tracker is canceled');
            } else if (this.state.object.data.locked) {
                notifications.push('This tracker is locked');
            } else {
                if (this.state.object.data.credit_hold) {
                    notifications.push('This company is on credit hold');
                }
                if (!this.state.object.data.shipto_validity) {
                    notifications.push('This ship to address could not be verified');
                }
                if (!this.state.object.data.ship_produce_location_match) {
                    notifications.push('Shipping location does not match production site');
                }
            }
            return notifications;
        },
        alertClass: function() {
            return this.state.object.data.credit_hold ? 'alert-danger' : 'alert-info';
        },
        editable: function() {
            let editable = this.state.object.data.permissions.edit;
            if (this.state.object.data.status && this.state.object.data.status.length > 0 || this.state.object.data.locked) {
                editable = false;
            }
            return editable;
        },
        fileUploadContext: function() {
            return this.$appStore.state.fileUploadContext;
        }
    },
    watch: {
        fileUploadContext: {
            handler(to){
                if (to === null) {
                    setTimeout(this.getCurbData, 500);
                }
            }
        },
        id: function (){
            this.load();
        },
        currentView: function() {
            if (this.state.currentView === 'history'){
                store.getHistory();
            } else if(this.currentView == 'search'){
                window.location.reload()
            } else if (this.state.currentView == 'invoice') {
                this.$refs.invoiceComponent.loadInvoice();
            } else if (this.state.currentView == 'notebook') {
                store.state.customFileCallback = () => this.setNotes();
            } else if (this.state.currentView == 'shipping') {
                store.api('to_json_skids', {}).then((result) => {
                    this.skids = result;
                });
            } else if (this.state.currentView === 'item') {
                if (Object.keys(this.curbs).length === 0 && this.curbs.constructor === Object) {
                    this.getCurbData();
                }
            } else if (this.currentView !== 'newMisc' && this.currentView !== 'newTask') {
                this.curbs = {};
            } else {
                store.state.customFileCallback = null;
            }
        },
        skids: function() {
            this.customs = {
                canada: this.skids.canada,
                customs_bill_to: this.skids.customs_bill_to
            }
        },

    },

  directives: {
        clickOutside: clickOutside
    }
}
</script>

<style scoped>

</style>
