var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('RTUTag',{attrs:{"val":_vm.editCurb.tag},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "tag", $event)}}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-6 form-group"},[_c('label',[_vm._v("Type")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.editCurb.extension_id),expression:"editCurb.extension_id"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.editCurb, "extension_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.cache.curbTypeCache.EXTENSION_TYPE),function(ref){
var extension_id = ref[0];
var extensionType = ref[1];
return _c('option',{key:extension_id,domProps:{"value":extension_id}},[_vm._v(_vm._s(extensionType))])}),0)]),_c('Height',{attrs:{"val":_vm.editCurb.base_height},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "base_height", $event)}}}),_c('Model',{attrs:{"label":'New Model',"val":_vm.editCurb.new_model},on:{"update":function (newModel) {
                _vm.editCurb.cdi_part_id_new = newModel.cdi_part_id;
                _vm.editCurb.new_model_id = newModel.unit_model_id;
                _vm.editCurb.new_model = newModel.label;                
            }}})],1),_c('div',{staticClass:"row"},[_c('IsCustom',{attrs:{"val":_vm.editCurb.is_custom},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "is_custom", $event)}}}),_c('Applied',{attrs:{"val":_vm.editCurb.applied},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "applied", $event)}}}),_c('Sectional',{attrs:{"val":_vm.editCurb.sectional},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "sectional", $event)}}})],1),_c('div',{staticClass:"spacing_btm"},[_c('Conforming',{attrs:{"val":_vm.editCurb.conforming,"conformingId":_vm.editCurb.conforming_type_id,"label":_vm.editCurb.conforming_type,"isOshpd":_vm.editCurb.is_oshpd},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "conforming", $event)},"update":function (conforming_type_id, is_oshpd, conforming_location_id) {
              _vm.editCurb.conforming_type_id = conforming_type_id;
              _vm.editCurb.is_oshpd = is_oshpd;
              _vm.editCurb.conforming_location_id = conforming_location_id;
            }}})],1),_c('div',{staticClass:"spacing_top"},[_c('input',{staticClass:"btn btn-default",attrs:{"type":"button","value":"Save"},on:{"click":_vm.save}}),_c('input',{staticClass:"btn btn-default",attrs:{"type":"button","value":"Cancel"},on:{"click":_vm.cancel}})])])}
var staticRenderFns = []

export { render, staticRenderFns }