<template>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
        <label>Frame</label>
        <select v-model="frame" class="form-control" @change="$emit('update:val', frame)">
            <option v-for="frameOption in cache.curbTypeCache.BURGLAR_FRAME" :key="frameOption[0]" :value="frameOption[0]">{{ frameOption[1] }}</option>
        </select>
    </div>
</template>

<script>
import curbTypeCache from  '@/cache/curbType.cache.js';
export default {
    name: "Frame",
    props: ['val'],
    data() {
        return {
            frame: 0,
            cache: {
                curbTypeCache
            },
        }
    },
    computed: {
    },
    created() {
        this.frame = this.val;
    },
}
</script>

<style scoped>

</style>