<template>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
        <label>Type</label>
        <select v-model="side_type_id" class="form-control" @change="$emit('update:val', side_type_id)">
            <option v-for="sideType in sideTypes" :key="sideType[0]" :value="sideType[0]">{{ sideType[1] }}</option>
        </select>
    </div>
</template>

<script>
import curbTypeCache from  '@/cache/curbType.cache.js';
export default {
    name: "SideType",
    props: ['val', 'curbTypeID'],
    data() {
        return {
            side_type_id: 0,
            cache: {
                curbTypeCache
            },
        }
    },
    computed: {
        sideTypes: function() {
            if (this.curbTypeID === this.cache.curbTypeCache.STRAIGHT_CURB) {
                return [
                    this.cache.curbTypeCache.SIDE_TYPES[0],
                    this.cache.curbTypeCache.SIDE_TYPES[7],
                ]
            }else if (this.curbTypeID === this.cache.curbTypeCache.ADJUSTABLE_PITCHED_CURB) {
                return [
                    this.cache.curbTypeCache.SIDE_TYPES[5],
                ]
            }else if (this.curbTypeID === this.cache.curbTypeCache.EQUIPMENT_RAILS) {
                return [
                    this.cache.curbTypeCache.SIDE_TYPES[0],
                    this.cache.curbTypeCache.SIDE_TYPES[1],
                    this.cache.curbTypeCache.SIDE_TYPES[2],
                    this.cache.curbTypeCache.SIDE_TYPES[6],
                ]
            }else if (this.curbTypeID === this.cache.curbTypeCache.ISO_CURB) {
                return [
                    this.cache.curbTypeCache.SIDE_TYPES[0],
                    this.cache.curbTypeCache.SIDE_TYPES[1],
                    this.cache.curbTypeCache.SIDE_TYPES[2],
                ]
            } else {
                return this.cache.curbTypeCache.SIDE_TYPES;
            }
        },
    },
    created() {
        this.side_type_id = this.val;
    },
}
</script>

<style scoped>

</style>