<template>
    <transition name="modal_fade">
        <div class="modal_backdrop">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">

                <div class="modal_header">
                    {{ header }}
                    <button type="button" class="modal_btn_close" @click="close(false)" aria-label="Close modal"><span class="glyphicon glyphicon-remove"></span></button>
                </div>

                <div class="modal_body">
                    <div v-if="!skidId">
                        <div class="col-sm-6 col-xs-12 form-group">
                            <label>Length</label>
                            <input type=text v-model="forms.newSkid.length.val" class="form-control">
                        </div>
                        <div class="col-sm-6 col-xs-12 form-group">
                            <label>Width</label>
                            <input type=text v-model="forms.newSkid.width.val" class="form-control">
                        </div>
                        <div class="col-sm-6 col-xs-12 form-group">
                            <label>Height</label>
                            <input type=text v-model="forms.newSkid.height.val" class="form-control">
                        </div>
                        <div class="col-sm-6 col-xs-12 form-group">
                            <label>Weight</label>
                            <input type=text v-model="forms.newSkid.weight.val" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="modal_footer">
                    <button type="button" class="btn btn-primary" @click="close(true)" aria-label="Close modal">Skid</button>
                    <button type="button" class="btn btn-default" @click="close(false)" aria-label="Close modal">Cancel</button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

import helpers from '@/store/helpers';
import { store } from '@/store/BusinessObject.store'

export default {
    name: 'SkidModal',
    data() {
        return {
            forms: {
                newSkid: {
                    height: {val: 0, qsField: '[skid_height]'},
                    width: {val: 0, qsField: '[skid_width]'},
                    length: {val: 0, qsField: '[skid_length]'},
                    weight: {val: 0, qsField: '[skid_weight]'},
                    trackerInvoiceId: {val: [], qsField: '[tracker_invoice_id]'}
                },
                existingSkid: {
                    trackerInvoiceId: {val: [], qsField: '[tracker_invoice_id]'},
                    trackerSkidId: {val: '', qsField: '[tracker_skid_id]'}
                }
            }
        }
    },
    props: ['skidId', 'trackerInvoiceId', 'dimensions'],
    methods: {
        close: function (save) {
            if (save) {
                this.save();
            }
            this.$emit('close');
        },
        save: function() {
            let params = {};
            if (this.skidId === '') {
                params = helpers.getQSPairs(this.forms.newSkid)
            } else {
                params = helpers.getQSPairs(this.forms.existingSkid)
            }
            for (let i = 0; i < this.trackerInvoiceId.length; i++) {
                params['params[tracker_invoice_id][' + i + ']'] = this.trackerInvoiceId[i];
            }
            store.api('skid_curb', params, 'skids')
            .then(() => {
                this.$emit('refreshSkid')
            });
        }
    },
    computed: {
        header: function() {
            return `Skid ${ this.trackerInvoiceId.length } ${ (this.trackerInvoiceId.length > 1) ? 'items' : 'item' } ${ (this.skidId) ? 'on skid ' + this.skidId : ''}`
        }
    },
    watch: {
        skidId: function() {
            this.forms.existingSkid.trackerSkidId.val = this.skidId;
        },
        dimensions: {
            handler: function() {
                this.forms.newSkid.height.val = this.dimensions.height;
                this.forms.newSkid.weight.val = this.dimensions.weight;
                this.forms.newSkid.length.val = this.dimensions.length;
                this.forms.newSkid.width.val = this.dimensions.width;
            },
            deep: true
        }
    }
}
</script>

<style scoped>
.modal {
    width: 330px;
    height: 250px;
    min-height: 150px;
}
button {
    margin-left: 5px;
}
</style>