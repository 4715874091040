<template>
    <transition name='fade'>
        <div v-show='active' class="alert alert-info" role="alert" id='toast'>
            <span v-html='state.toast.text'></span><br />
            <span class='pointer' @click='toastAction' v-if='state.toast.link' v-html='state.toast.link'></span>
        </div>
    </transition>
</template>

<script>

export default {
    name: 'Toast',
    data() {
        return {
            state: this.$appStore.state,
            active: false
        }
    },
    methods: {
        activate: function() {
            if (this.text || this.link) {
                this.active = true;
                setTimeout(this.close, 5000);
                setTimeout(this.reset, 6000);
            }
        },
        close: function() {
            this.active = false;
        },
        toastAction: function() {
            this.$emit('toastAction');
        },
        reset: function() {
            this.$appStore.toast('', '');
        }
    },
    computed: {
        text: function() {
            return this.state.toast.text;
        },
        link: function() {
            return this.state.toast.link;
        }
    },
    watch: {
        text: function() {
            this.activate();
        },
        link: function() {
            this.activate();
        }
    }
}

</script>

<style scoped>

#toast {
    position: fixed;
    margin: 0;
    bottom: 10px;
    right: 10px;
    z-index: 99999;
}

</style>