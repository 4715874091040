var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('RTUTag',{attrs:{"val":_vm.editCurb.tag},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "tag", $event)}}})],1),_c('div',{staticClass:"row"},[_c('Model',{attrs:{"label":'Old Model',"val":_vm.editCurb.old_model},on:{"update":function (oldModel) {
                _vm.editCurb.cdi_part_id_old = oldModel.cdi_part_id;
                _vm.editCurb.old_model_id = oldModel.unit_model_id;
                _vm.editCurb.old_model = oldModel.label;                
            }}}),_c('Model',{attrs:{"label":'New Model',"val":_vm.editCurb.new_model},on:{"update":function (newModel) {
                _vm.editCurb.cdi_part_id_new = newModel.cdi_part_id;
                _vm.editCurb.new_model_id = newModel.unit_model_id;
                _vm.editCurb.new_model = newModel.label;                
            }}}),_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-6 form-group"},[_c('label',[_vm._v("# of Zones")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editCurb.spacing),expression:"editCurb.spacing"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.editCurb.spacing)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.editCurb, "spacing", $event.target.value)}}})])],1),_c('div',{staticClass:"row"},[_c('IsCustom',{attrs:{"val":_vm.editCurb.is_custom},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "is_custom", $event)}}}),_c('Applied',{attrs:{"val":_vm.editCurb.applied},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "applied", $event)}}}),_c('Sectional',{attrs:{"val":_vm.editCurb.sectional},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "sectional", $event)}}})],1),_c('div',{staticClass:"spacing_btm"},[_c('Conforming',{attrs:{"val":_vm.editCurb.conforming,"conformingId":_vm.editCurb.conforming_type_id,"label":_vm.editCurb.conforming_type,"isOshpd":_vm.editCurb.is_oshpd},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "conforming", $event)},"update":function (conforming_type_id, is_oshpd, conforming_location_id) {
              _vm.editCurb.conforming_type_id = conforming_type_id;
              _vm.editCurb.is_oshpd = is_oshpd;
              _vm.editCurb.conforming_location_id = conforming_location_id;
            }}})],1),_c('div',{staticClass:"spacing_top"},[_c('input',{staticClass:"btn btn-default",attrs:{"type":"button","value":"Save"},on:{"click":_vm.save}}),_c('input',{staticClass:"btn btn-default",attrs:{"type":"button","value":"Cancel"},on:{"click":_vm.cancel}})])])}
var staticRenderFns = []

export { render, staticRenderFns }