<template>
    <div class="col-xs-6 form-group">
        <label>{{ label }}</label>
        <Typeahead ref="model" :cdi_type="cdiType" @objectSelected="(data) => $emit('update', data)" :placeholder="''" />
    </div>
</template>

<script>
import Typeahead from '@/components/utils/typeaheads/Typeahead';
import curbTypeCache from  '@/cache/curbType.cache.js';
export default {
    name: "Model",
    props: ['label', 'val', 'curbTypeID'],
    data() {
        return {
            cache: {
                curbTypeCache
            },
        }
    },
    components: {
        Typeahead,
    },
    computed: {
        cdiType: function() {
          if(this.curbTypeID === this.cache.curbTypeCache.ERV_COMBO) {
            return 'pm_erv';
          } else if(this.curbTypeID === this.cache.curbTypeCache.VRF_STRAIGHT_CURB || this.curbTypeID === this.cache.curbTypeCache.VRF_PITCHED_CURB){
            return 'pm_model_vrf'
          } else {
            return 'pm_model'
          }
        },
    },
    mounted() {
        this.$refs.model.$data.object = this.val;
    }
}
</script>

<style scoped>

</style>