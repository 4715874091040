<template>
    <div>
        <div class="row">
            <RTUTag :val.sync="editCurb.tag" />
        </div>
        <div class="row">
            <Part
                :val="editCurb.cdi_part_id_old"
                :curbTypeID="curb.curb_type_id"
                @update="(part) => {
                    editCurb.cdi_part_id_old = part.value;
                }"
            />
        </div>
        <div class="row">
            <Applied :val.sync="editCurb.applied" />
            <Sectional :val.sync="editCurb.sectional" />
        </div>

        <div class="spacing_top">
            <input class="btn btn-default" type="button" value="Save" @click="save">
            <input class="btn btn-default" type="button" value="Cancel" @click="cancel">
        </div>
    </div>
</template>

<script>
import RTUTag from './fields/RTUTag';
import Applied from "./fields/Applied";
import Sectional from "./fields/Sectional";
import Part from "./fields/Part";

export default {
    name: "Miscellaneous",
    components: {
        RTUTag,
        Applied,
        Sectional,
        Part,
    },
    props: ["curb"],
    data() {
        return {
            editCurb: {
                tracker_invoice_id: 0,
                tag: '',
                cdi_part_id_old: '',
                applied: 0,
                sectional: 0,
            },
        }
    },
    methods: {
        save: function() {
            if (!this.errors()) {
                this.$emit('save', this.editCurb);
            }
        },
        cancel: function() {
            this.$emit('cancel');
        },
        errors: function() {
            let errors = [];

            if (errors.length) {
                this.$appStore.errorBox(errors.join("\n"));

                return true;
            }

            return false;
        },
        load: function() {
            for (const field in this.editCurb) {
                this.editCurb[field] = this.curb[field];
            }
        },
    },
    created() {
        if (this.curb.tracker_invoice_id) {
            this.load();
        }
    }
}
</script>

<style scoped>

</style>