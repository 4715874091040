<template>
    <div>
        <div class="row">
            <RTUTag :val.sync="editCurb.tag" />
        </div>
        <div class="row">
            <SideType :val.sync="editCurb.side_type_id" :curbTypeID="curb.curb_type_id" />
            <Pitch v-show="editCurb.side_type_id !== 0" :val.sync="editCurb.pitch_id" :curbTypeID="curb.curb_type_id" />
            <Height :val.sync="editCurb.base_height" />
            <Dimension :label="'Length'" :val.sync="editCurb.length_ft" :val2.sync="editCurb.length_inch_id" />
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>ER Model</label>
                <select v-model="editCurb.cdi_part_id_new" class="form-control">
                    <option value="886" >ER100</option>
                    <option value="887" >ER200</option>
                    <option value="888" >ER300</option>
                </select>
            </div>
        </div>
        <div class="row">
            <IsCustom :val.sync="editCurb.is_custom" />
            <Applied :val.sync="editCurb.applied" />
            <Sectional :val.sync="editCurb.sectional" />
        </div>
        <div class="spacing_btm">
          <Conforming
              :val.sync="editCurb.conforming"
              :conformingId="editCurb.conforming_type_id"
              :label="editCurb.conforming_type"
              :isOshpd="editCurb.is_oshpd"
              @update="(conforming_type_id, is_oshpd, conforming_location_id) => {
                  editCurb.conforming_type_id = conforming_type_id;
                  editCurb.is_oshpd = is_oshpd;
                  editCurb.conforming_location_id = conforming_location_id;
                }"
          />
        </div>

        <div class="spacing_top">
            <input class="btn btn-default" type="button" value="Save" @click="save">
            <input class="btn btn-default" type="button" value="Cancel" @click="cancel">
        </div>
    </div>
</template>

<script>
import RTUTag from './fields/RTUTag';
import IsCustom from "./fields/IsCustom";
import Applied from "./fields/Applied";
import Sectional from "./fields/Sectional";
import Conforming from './fields/Conforming';
import SideType from './fields/SideType';
import Pitch from './fields/Pitch';
import Height from './fields/Height';
import Dimension from "./fields/Dimension";

export default {
    name: "EquipmentRails",
    components: {
        RTUTag,
        IsCustom,
        Applied,
        Sectional,
        Conforming,
        SideType,
        Pitch,
        Height,
        Dimension,
    },
    props: ["curb"],
    data() {
        return {
            editCurb: {
                tracker_invoice_id: 0,
                tag: '',
                side_type_id: 0,
                pitch_id: 0,
                base_height: 14,
                length_ft: 6,
                length_inch_id: 0,
                cdi_part_id_new: 886,
                is_custom: 0,
                applied: 0,
                sectional: 0,
                conforming: 0,
                conforming_type_id: 0,
                conforming_type: "",
                is_oshpd: 0,
            },
        }
    },
    methods: {
        save: function() {
            if (!this.errors()) {
                this.$emit('save', this.editCurb);
            }
        },
        cancel: function() {
            this.$emit('cancel');
        },
        errors: function() {
            let errors = [];
            if (!parseInt(this.editCurb.new_model_id) && !parseInt(this.editCurb.cdi_part_id_new)) {
                errors.push('Need to select New Model');
            }
            if (this.editCurb.conforming == 1 && !this.editCurb.conforming_type_id){
              errors.push('Conforming Stamp Location and Year is required.');
            }
            if (errors.length) {
                this.$appStore.errorBox(errors.join("\n"));

                return true;
            }

            return false;
        },
        load: function() {
            for (const field in this.editCurb) {
                this.editCurb[field] = this.curb[field];
            }
        },
    },
    created() {
        if (this.curb.tracker_invoice_id) {
            this.load();
        }
    }
}
</script>

<style scoped>

</style>