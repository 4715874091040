var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('RTUTag',{attrs:{"val":_vm.editCurb.tag},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "tag", $event)}}})],1),_c('div',{staticClass:"row"},[_c('SideType',{attrs:{"val":_vm.editCurb.side_type_id,"curbTypeID":_vm.curb.curb_type_id},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "side_type_id", $event)}}}),_c('Pitch',{attrs:{"val":_vm.editCurb.pitch_id,"curbTypeID":_vm.curb.curb_type_id},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "pitch_id", $event)}}}),_c('Height',{attrs:{"val":_vm.editCurb.base_height},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "base_height", $event)}}})],1),_c('div',{staticClass:"row"},[_c('Model',{attrs:{"label":'New VRF Model',"val":_vm.editCurb.new_model,"curbTypeID":_vm.curb.curb_type_id},on:{"update":function (newModel) {
                  _vm.editCurb.cdi_part_id_new = newModel.cdi_part_id;
                  _vm.editCurb.new_model_id = newModel.unit_model_id;
                  _vm.editCurb.new_model = newModel.label;
              }}})],1),_c('div',{staticClass:"row"},[_c('IsCustom',{attrs:{"val":_vm.editCurb.is_custom},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "is_custom", $event)}}}),_c('Applied',{attrs:{"val":_vm.editCurb.applied},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "applied", $event)}}}),_c('Sectional',{attrs:{"val":_vm.editCurb.sectional},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "sectional", $event)}}})],1),_c('div',{staticClass:"spacing_btm"},[_c('Conforming',{attrs:{"val":_vm.editCurb.conforming,"conformingId":_vm.editCurb.conforming_type_id,"label":_vm.editCurb.conforming_type,"isOshpd":_vm.editCurb.is_oshpd},on:{"update:val":function($event){return _vm.$set(_vm.editCurb, "conforming", $event)},"update":function (conforming_type_id, is_oshpd, conforming_location_id) {
                _vm.editCurb.conforming_type_id = conforming_type_id;
                _vm.editCurb.is_oshpd = is_oshpd;
                _vm.editCurb.conforming_location_id = conforming_location_id;
              }}})],1),_c('div',{staticClass:"spacing_top"},[_c('input',{staticClass:"btn btn-default",attrs:{"type":"button","value":"Save"},on:{"click":_vm.save}}),_c('input',{staticClass:"btn btn-default",attrs:{"type":"button","value":"Cancel"},on:{"click":_vm.cancel}})])])}
var staticRenderFns = []

export { render, staticRenderFns }