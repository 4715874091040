<template>
    <div class="line_drawings">
        <button v-show="photos.length && !showPhotos" @click="showPhotos = true" class="btn btn-link">({{ photos.length }}) Photos</button>
        <div class="panel panel-default panel-body" v-show="showPhotos">
            <div class="cdi_file_box" v-for="photo in photos" :key="photo">
                <router-link target="_blank" :to="photo.url"><span class="glyphicon glyphicon-info-sign icon_md"></span></router-link>
                <br />
                <a target="_blank" :href="photo.url">
                    <img :src="photo.url" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Photos',
    props: ['photos'],
    data() {
        return {
            showPhotos: false
        }
    },
    computed: {
        baseUrl: function() {
            return process.env.VUE_APP_URL;
        }
    }
}

</script>