<template>
    <BillModal :heading="'Bill Of Lading Skids'" @close="close()" v-if="show">
        <template v-slot:body>
            <label>
                <input type="checkbox" v-model="allChecked" @change="toggleAll"> Check/uncheck all
            </label>
            <div v-for="skid in skids" :key="skid.tracker_skid_id">
                <label :class="skid.printed_out ? 'strikeout' : ''">
                    <input type="checkbox" v-model="checkedSkids" :value="skid.tracker_skid_id">
                    Skid #{{skid.tracker_skid_id}} - {{skid.skid_length}} x {{skid.skid_width}} x {{skid.skid_height}} | Weight: {{skid.skid_weight}} & Class: {{skid.skid_class}}
                </label>
                <div v-for="tracker_curb in skid.curbs" :key="tracker_curb.tracker_invoice_id" class="spacing_left" :class="skid.printed_out ? 'strikeout' : ''">{{tracker_curb.line}} - {{tracker_curb.nomenclature}}</div>
            </div>
        </template>
        <template v-slot:footer>
            <button class="cdi_button btn btn-primary" type="button" @click="openPDF">Create PDF</button>
            <button class="cdi_button btn btn-default" type="button" @click="close">Cancel</button>
        </template>
    </BillModal>
</template>

<script>
import BillModal from "@/components/businessObjects/tracker/BillModal";
import { store } from '@/store/BusinessObject.store'

export default {
    name: "BillOfLadingModal",
    props: ['trackerID'],
    components: {
        BillModal
    },
    data() {
        return {
            show: false,
            checkedSkids: [],
            allChecked: false,
            skids: {},
        }
    },
    methods: {
        openPDF: function() {
             let qs = this.checkedSkids.map(tracker_skid_id => {
                 return `params[tracker_skid_ids][]=${tracker_skid_id}`
             });
            window.open(`${this.$cdiVars.API_URL}/tracker_pdf/bill_ladening/${this.trackerID}?${qs.join('&')}&params[seed]=${Math.random()}`, '_blank').focus();
            this.close();
        },
        open: function() {
            store.api('to_json_skids')
            .then(result => {
                this.skids = result.skids
                this.show = true;
            });
        },
        close: function() {
            this.show = false;
        },
        toggleAll: function() {
            if (this.allChecked) {
                this.checkedSkids = this.skids.map(skid => skid.tracker_skid_id);
            } else {
              this.checkedSkids = [];
            }
        },
    }
}
</script>

<style scoped>

</style>