<template>
    <div v-show="(customerNotes || companyNotes)" class="alert alert-info">
        This Customer/Company has Notes! <span class="cdi_link" @click="showNotes = !showNotes">(Click to toggle view)</span>
        <div v-show="showNotes">
            <label>Customer Notes</label>
            <p class='preformatted'>{{ customerNotes }}</p>
            <label>Company Notes</label>
            <p class='preformatted'>{{ companyNotes }}</p>
        </div>

    </div>
</template>

<script>

export default {
    name: 'Notes',
    props: ['customerNotes', 'companyNotes'],
    data() {
        return {
            showNotes: false
        }
    }
}

</script>

<style scoped >
    .preformatted {
        white-space: pre;
    }
</style>