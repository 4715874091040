<template>
    <Modal :heading="'Change Reason'" @close="close()" v-if="show">
        <template v-slot:body>
            <b>Reason</b>
            <select class='form-control' v-model='reason'>
                <option v-for='[id, reason] in cache.shippingCache.SCHEDULED_SHIP_CHANGE_REASONS' :key='id' :value='id'>{{ reason }}</option>
            </select>
        </template>
        <template v-slot:footer>
            <button class="cdi_button btn btn-default" type="button" @click="save()">Save</button>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/utils/Modal';
import shippingCache from '@/cache/shipping.cache';

export default {
    name: "ScheduledShipModal",
    components: {
        Modal
    },
    data() {
        return {
            reason: 319,
            show: false,
            cache: {
                shippingCache
            }
        }
    },
    props: ['quoteID'],
    methods: {
        save: function() {
            this.$emit('save', this.reason)
            this.close();
        },
        open: function() {
            this.show = true;
        },
        close: function() {
            this.show = false;
        }
    }
}
</script>

<style scoped>

</style>