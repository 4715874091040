<template>
    <div>
        <h3>Order Tasks</h3>
        <div v-for="(task, index) in state.object.data.order_tasks" v-bind:key="index">
            <span v-if="task.can_edit">
                <span v-if="task.complete" class="glyphicon glyphicon-share-alt cdi_link mirror" @click="showUncompleteTask(task.tracker_task_id)"></span>
            </span>
            <b>{{ task.task }}</b> by {{ task.employee}} for {{ task.duration }} mins on {{ task.taskdate }}
        </div>
        <ConfirmBox
            v-if="showTaskConfirm"
            @close="uncompleteTask"
            :heading="'Uncomplete Task?'"
            :body="'Would you like to uncomplete this task?'"
        />
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store'
import ConfirmBox from '@/components/utils/ConfirmBox';
import helpers from '@/store/helpers';

export default {
    name: 'OrderTasks',
    components: {ConfirmBox},
    data() {
        return {
            state: store.state,
            showTaskConfirm: false,
            forms: {
                uncompleteTask: {
                    selectedTask: {val: 0, qsField: ''}
                }
            }
        }
    },
    methods: {
        showUncompleteTask: function(id) {
            this.forms.uncompleteTask.selectedTask.val = id;
            this.showTaskConfirm = true;
        },
        uncompleteTask: function(confirmed) {
            if (confirmed) {
                store.api('uncomplete_task', helpers.getQSPairs(this.forms.uncompleteTask), 'object.data')
            }
            this.selectedTask = 0;
            this.showTaskConfirm = false;
        }
    }
}

</script>