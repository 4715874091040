<template>
    <div>
        <div class="spacing_btm">
            <input class="lock btn btn-default" type="button" @click="sendNotification" value="Send Notification">
            <input class="btn btn-default" type="button" @click="openBillOfLading" value="Bill of Lading">
            <input class="btn btn-default" type="button" v-show="state.object.data.shipping_co_id === 23" @click="autoRoute" value="Auto Route">
            <input class="btn btn-default" type="button" v-show="appState.user.userTypeID === 4" @click="resetShipper" value="Resend (3PL)">
        </div>

        <h3>Shipment Addresses</h3>
        <ShipmentAddresses
            :values="{
                    shipperAddress: {
                        addressName: stateData.shipper_name,
                        address: stateData.shipper_address,
                        city: stateData.shipper_city,
                        state: stateData.shipper_state,
                        zip: stateData.shipper_zip
                    },
                    shipToAddress: {
                        addressName: stateData.shipto_name,
                        address: stateData.shipto_address,
                        city: stateData.shipto_city,
                        state: stateData.shipto_state,
                        zip: stateData.shipto_zip
                    }
                }"
            :permission="lockRules && !creditCardLock"
        />
        <h3>Shipping Details</h3>
        <div class="panel panel-default">
            <div class="row panel-body details_container">
                <h4 style="margin-left: 15px">Call Ahead</h4>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox
                        :val="stateData.call_ahead"
                        :field="{
                            name: 'call_ahead',
                            label: 'Call Ahead',
                        }"
                        @save="save"
                    />
                </div>
                <div v-if="stateData.call_ahead" class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText
                        :val="stateData.contact_name"
                        :field="{
                            name: 'contact_name',
                            label: 'Contact Name',
                            permission: lockRules
                        }"
                        @save="save"
                    />
                </div>
                <div v-if="stateData.call_ahead" class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText
                        :val="stateData.contact_phone"
                        :field="{
                            name: 'contact_phone',
                            label: 'Contact Phone',
                            permission: lockRules
                        }"
                        @save="save"
                    />
                </div>
                <div v-if="stateData.call_ahead" class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsText
                    :val="stateData.contact_email"
                    :field="{
                            name: 'contact_email',
                            label: 'Contact Email',
                            permission: lockRules
                        }"
                    @save="save"
                />
            </div><div v-if="stateData.call_ahead" class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsText
                    :val="stateData.contact_email_cc"
                    :field="{
                            name: 'contact_email_cc',
                            label: 'Contact Email CC',
                            permission: lockRules
                        }"
                    @save="save"
                />
            </div>
                <div v-if="stateData.call_ahead" class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox
                        :val="stateData.call_ahead_complete"
                        :field="{
                            name: 'call_ahead_complete',
                            label: 'Call Ahead Completed',
                        }"
                        @save="saveCallAhead"
                    />
                </div>
            </div>
        </div>
        <div class="panel panel-default ">
            <div class="row panel-body details_container">
                <div v-if="customs.canada" class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsTypeahead
                        :val="stateData.shipping_co_id"
                        :field="{
                            name: 'customs_bill_to',
                            display: customs.customs_bill_to,
                            label: 'Customs Bill To',
                            cdiType: 'bill_to',
                            permission: lockRules,
                            params: {'params': stateData.company_id}
                        }"
                        @save="saveCustomsBillTo"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsTextarea
                        :val="stateData.shipto_instructions"
                        :field="{
                            name: 'shipto_instructions',
                            label: 'Shipping Instructions',
                            permission: lockRules
                        }"
                        @save="save"
                    />
                </div>
                <div v-if="!stateData.call_ahead" class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText
                        :val="stateData.contact_name"
                        :field="{
                            name: 'contact_name',
                            label: 'Contact Name',
                            permission: lockRules
                        }"
                        @save="save"
                    />
                </div>
                <div v-if="!stateData.call_ahead" class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText
                        :val="stateData.contact_phone"
                        :field="{
                            name: 'contact_phone',
                            label: 'Contact Phone',
                            permission: lockRules
                        }"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsDate
                        :val="stateData.scheduled_ship"
                        :field="{
                            name: 'scheduled_ship',
                            label: 'Scheduled Ship',
                            permission: lockRules
                        }"
                        @save="openScheduledShipModal"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsDate
                    :val="stateData.actual_ship"
                    :field="{
                            name: 'actual_ship',
                            label: 'Actual Ship',
                            permission: (lockRules || stateData.permissions.ship_date)
                        }"
                    @save="saveActualShip"
                />
                </div>

                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsDate
                        :val="(stateData.hold || stateData.credit_hold) ? 'On Hold' : stateData.sch_receive_ship"
                        :field="{
                            name: 'sch_receive_ship',
                            label: 'Scheduled Delivery',
                            permission: (lockRules || stateData.permissions.scheduled_ship)
                        }"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsDate
                        :val="stateData.receive_ship"
                        :field="{
                            name: 'receive_ship',
                            label: 'Delivered'
                        }"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox
                        :val="stateData.req_delivery_date"
                        :field="{
                            name: 'req_delivery_date',
                            label: 'Required Delivery'
                        }"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox
                        :val="stateData.fully_crate"
                        :field="{
                            name: 'fully_crate',
                            label: 'Fully Crate'
                        }"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsTypeahead
                        :val="stateData.shipping_co_id"
                        :field="{
                            name: 'shipping_co_id',
                            display: stateData.shipping_co,
                            label: 'Shipper',
                            link: 'Shipper',
                            cdiType: 'shipping_co'
                        }"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect
                        :val="stateData.trucking_options_id"
                        :field="{
                            name: 'trucking_options_id',
                            label: 'Trucking',
                            options: cache.ShippingCache.SHIPPING_OPTIONS
                        }"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox
                        :val="stateData.flatbed"
                        :field="{
                            name: 'flatbed',
                            label: 'Flatbed',
                            permission: lockRules
                        }"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect
                        :val="stateData.ship_account_num"
                        :field="{
                            name: 'ship_account_num',
                            label: 'Ship Acc',
                            options: [
                                ['', 'None'],
                                [4035, 4035],
                                [4040, 4040],
                                [4045, 4045],
                                [4050, 4050],
                                [4055, 4055]
                            ],
                            excludeDefault: true
                        }"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText
                        :val="stateData.BOL_description"
                        :field="{
                            name: 'BOL_description',
                            label: 'BoL Description',
                            permission: lockRules
                        }"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText
                        :val="stateData.truck_booked"
                        :field="{
                            name: 'truck_booked',
                            label: 'Booked',
                            permission: lockRules
                        }"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText
                        :val= stateData.tracking_pro_num
                        :field="{
                            name: 'tracking_pro_num',
                            label: 'Tracking PRO #',
                            url: `${stateData.url}${stateData.tracking_pro_num}`,
                            tag: (stateData.epro_override) ? 'Override' : ''
                        }"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect
                        :val="stateData.can_receive"
                        :field="{
                            name: 'can_receive',
                            label: 'Can Receive',
                            options: [
                                [1, 'Yes'],
                                [2, 'No'],
                            ]
                        }"
                        @save="save"
                    />
                </div>
                <div v-if="stateData.ship_control" class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsStatic
                        :val="stateData.shipping_status"
                        :field="{
                            name: 'shipping_status',
                            label: 'Ship Status'
                        }"
                        @save="save"
                    />
                </div>
                <div v-if="!stateData.ship_control" class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect
                        :val="stateData.shipping_status"
                        :field="{
                            name: 'shipping_status',
                            label: 'Ship Status',
                            options: [
                                ['In Production', 'In Production'],
                                ['Intransit', 'Intransit'],
                                ['At Delivery Terminal', 'At Delivery Terminal'],
                                ['Out for Delivery', 'Out for Delivery'],
                                ['Delivered', 'Delivered']
                            ]
                        }"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsStatic
                        :val="stateData.shipping_status_date"
                        :field="{
                            name: 'shipping_status_date',
                            label: 'Ship Status Date'
                        }"
                        @save="save"
                    />
                </div>
            </div>
        </div>
        <div>
            <h3>Freight Costs</h3>
            <div class="row panel-body details_container">
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText
                            :val="stateData.freight_cost"
                            :field="{
                            name: 'freight_cost',
                            label: 'Freight Cost',
                            permission: !creditCardLock
                        }"
                            @save="saveFreightCost"
                    />
                </div>

                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <div>
                        <label>Additional Freight Cost</label>
                    </div>
                    <div class="margin_bottom">
                        ${{this.stateData.late_detention_fee}} : {{this.freightReason}}
                    </div>
                    <button v-if="!creditCardLock" class="btn btn-default" @click="openAdditionalFreightCostModal">Add Additional Freight Cost</button>
                </div>
                
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <DetailsCheckbox
                        :val="stateData.shipping_paid"
                        :field="{
                        name: 'shipping_paid',
                        label: 'Paid',
                    }"
                        @save="save"
                    />
                </div>
            </div>
        </div>

        <ScheduledShipModal ref="scheduledShipModal" @save='saveScheduledShip' />
        <BillOfLadingModal ref="billOfLadingModal" :trackerID="stateData.tracker_id" />
        <Modal @close='closeEmailModal' v-if='showConfirmEmailModal' :heading='`Order Email`'>
            <template v-slot:body >
                <div>
                    <p class='spacing_btm'>Email Customer a copy of their order?</p>
                    <label>Notes:</label>
                    <textarea rows='5' v-model='confirmBoxNotes' class='form-control' ></textarea>
                </div>
            </template>
            <template v-slot:footer>
                <div>
                    <button class="btn btn-primary" @click="sendEmailConfirmation">Yes</button>
                    <button class="btn btn-default" @click="closeEmailModal">No</button>
                </div>
            </template>
        </Modal>
        <Modal @close='closeAdditionalFreightCostModal' v-if='additionalFreightCostModal' :heading='`Additional Freight Cost`'>
            <template v-slot:body >
                <div>
                    <DetailsSelect
                        ref="feeTypeId"
                        :val="stateData.fee_type_id"
                        :field="{
                            name: 'fee_type_id',
                            label: saveSuccess ? '** Additional Freight Cost Reason' : '** This field is required **',
                            options: cache.ShippingCache.FEE_TYPES
                        }"
                        @save="saveFees"
                        :class="{'red-text': !saveSuccess}"
                    />
                    <DetailsText
                            v-if="showFee"
                            ref="lateDetentionFee"
                            :val="stateData.late_detention_fee"
                            :field="{
                            name: 'late_detention_fee',
                            label: 'Additional Freight Cost'
                        }"
                            @save="saveFees"
                    />
                </div>
            </template>
        </Modal>
        <Toast @toastAction='openEmailModal'/>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store'
import DetailsStatic from '@/components/businessObjects/utils/DetailsStatic';
import DetailsText from '@/components/businessObjects/utils/DetailsText';
import DetailsSelect from '@/components/businessObjects/utils/DetailsSelect';
import DetailsDate from '@/components/businessObjects/utils/DetailsDate';
import DetailsCheckbox from '@/components/businessObjects/utils/DetailsCheckbox';
import DetailsTextarea from '@/components/businessObjects/utils/DetailsTextarea';
import ShipmentAddresses from './ShipmentAddresses';
import DetailsTypeahead from '@/components/businessObjects/utils/DetailsTypeahead';
import ScheduledShipModal from "@/components/businessObjects/tracker/ScheduledShipModal";
import BillOfLadingModal from "@/components/businessObjects/tracker/BillOfLadingModal";
import ShippingCache from "@/cache/shipping.cache.js";
import Toast from "@/components/utils/Toast";
import Modal from "@/components/utils/Modal";
import appStore from "@/store/App.store";



export default {
    name: 'Shipping',
    props: ['customs', 'creditCardLock'],
    components: {
        DetailsSelect,
        DetailsText,
        DetailsCheckbox,
        DetailsDate,
        DetailsTextarea,
        DetailsStatic,
        ShipmentAddresses,
        DetailsTypeahead,
        ScheduledShipModal,
        BillOfLadingModal,
        Toast,
        Modal,
    },
    data() {
        return {
            state: store.state,
            scheduledShip: '',
            confirmBoxNotes: '',
            showConfirmEmailModal: false,
            additionalFreightCostModal: false,
            showFee: false,
            saveSuccess: true,
            appState: appStore.state,
            cache: {
                ShippingCache,
            }
        }
    },
    methods: {
        sendNotification: function() {
            store.api('send_shipping_notification');
        },
        openBillOfLading: function() {
            this.$refs.billOfLadingModal.open(true);
        },
        autoRoute: function() {
            store.apiWithObjectLoad('auto_route');
        },
        save: function(data) {
            store.save(data.field, data.val);
        },
        saveCallAhead: function(data) {
            store.save(data.field, data.val).then(this.createNote)
        },
        createNote: function () {
            if (this.state.object.data.call_ahead_complete){
                store.api('save_note', {'params[cdi_note]': 'This Call Ahead was marked as complete by ' + this.appState.user.userName}, 'object.data', 'tracker')
            }
        },
        saveFreightCost: function(data) {
            let params = {'params[freight_cost]': data.val};
            store.apiWithObjectLoad('save_freight_cost', params);
        },
        saveFees: function(data) {
            if (data.field == 'fee_type_id'){
                this.showFee = true;
                store.save(data.field, data.val);
            }else{
                store.save(data.field, data.val);
                this.closeAdditionalFreightCostModal();
            }
        },
        saveCustomsBillTo: function(data) {
            if (data.val) {
                store.api('save_customs_bill_to', {'params[customs_bill_to_id]' : data.val}).then( (result) => {
                    this.state.skids = result
                    this.$emit('refreshSkid');
                });
            }
        },
        saveActualShip: function(data) {
            let shipDate = new Date(data.val);

            if (data.val.length && !shipDate.getDate()) {
                this.$appStore.errorBox('Invalid Ship Date');
                return;
            }

            if (this.stateData.actual_ship != data.val) {
                if (data.val.length === 0 && this.stateData.invoice_sent) {
                    this.$appStore.errorBox('Must have ship date for invoiced trackers!');
                    return;
                }
            }
            this.save(data);
        },
        openScheduledShipModal: function(data) {
            this.scheduledShip = data.val;
            this.$refs.scheduledShipModal.open(true);
        },
        saveScheduledShip: function(reason) {
            let params = {
                'params[scheduled_ship_change_id]': reason,
                'params[scheduled_ship]': this.scheduledShip
            }
            store.apiWithObjectLoad('save', params).then(() => {
                if (this.state.object.data.toast_message) {
                    this.confirmBoxNotes = this.state.object.data.toast_message;
                    this.$appStore.toast(`Order updated!`,`<a class="tracker_toast_message alert-link">Email the customer an updated version of the order?</a>`);
                }
            });
        },
        openEmailModal: function() {
            this.showConfirmEmailModal = true;
        },
        closeEmailModal: function() {
            this.showConfirmEmailModal = false;
            this.confirmBoxNotes = '';
        },
        openAdditionalFreightCostModal: function() {
            this.additionalFreightCostModal = true;
        },
        closeAdditionalFreightCostModal: function() {
            this.additionalFreightCostModal = false;
        },
        sendEmailConfirmation: function() {
            store.api('send_order_confirmation_email', {'params[confirm_box_notes]' : this.confirmBoxNotes});
            this.closeEmailModal();
        },
        resetShipper: function (){
          store.api('reset_shipper');
        }
    },
    computed: {
        stateData: function() {
            return this.state.object.data;
        },
        lockRules: function() {
            if (!this.stateData) {
                return false;
            }
            return (this.stateData.permissions && !this.stateData.permissions.read_only || !this.stateData.locked);
        },
        freightReason: function (){
            const feeType = this.cache.ShippingCache.FEE_TYPES.find((item) => item[0] === this.stateData.fee_type_id);
            return feeType ? feeType[1] : 'Not Found';
        }
    }
}

</script>
<style scoped>
</style>