<template>
    <div class="object_detail">
        <label class="object_detail">{{ field.label }}</label>
        <div class="form-control">
            <router-link v-if="field.link" :to="`/${field.link}/${val}`">{{ field.display }}</router-link>
            <a v-if="field.url" target="_blank" :href="`${field.url}`">{{ field.display }}</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "DetailsLink",
    props: ['field', 'val'],
}
</script>

<style scoped>

</style>