<template>
    <div class='row'>
        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Cost</label>
            <input type=text v-model='cost' class="form-control"></div>
        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Description</label>
            <input type=text v-model='description' class="form-control">
        </div>
        <div class='col-sm-12'>
            <div>Add to {{curb.line}}{{curb.qty}} {{curb.nomenclature}}</div>
            <div>
                <button class='btn btn-default' @click='save'>Add Misc. Cost</button>
                <button class='btn btn-default' @click='close'>Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>

import { store } from '@/store/BusinessObject.store';

export default {
    name: 'NewMiscCost',
    props: ['curb'],
    data() {
        return {
            cost: '',
            description: ''
        }
    },
    methods: {
        save: function() {
            store.customApi(`tracker_curb/add_misc/${ this.curb.tracker_invoice_id }`, {
                'params[misc_cost]' : this.cost,
                'params[misc_desc]' : this.description
            });
            this.cost = '';
            this.description = '';
            this.close();
        },
        close: function() {
            this.$emit('close');
        }
    }
}

</script>