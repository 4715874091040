<template>
  <div>
    <div class="row">
      <RTUTag :val.sync="editCurb.tag" />
    </div>
    <div class="row">
      <Model
          :label="'Old Model'"
          :val="editCurb.old_model"
          @update="(oldModel) => {
                    editCurb.cdi_part_id_old = oldModel.cdi_part_id;
                    editCurb.old_model_id = oldModel.unit_model_id;
                    editCurb.old_model = oldModel.label;
                }"
      />
      <Model
          :label="'New Model'"
          :val="editCurb.new_model"
          @update="(newModel) => {
                    editCurb.cdi_part_id_new = newModel.cdi_part_id;
                    editCurb.new_model_id = newModel.unit_model_id;
                    editCurb.new_model = newModel.label;
                }"
      />
    </div>
    <div class="row">
      <IsCustom :val.sync="editCurb.is_custom" />
      <Applied :val.sync="editCurb.applied" />
      <Sectional :val.sync="editCurb.sectional" />
    </div>
    <CurbDepth :label="'Curb Depth'" :val.sync="editCurb.curb_depth_id"/>
    <TopOffset :label="'Top Offset'" :val.sync="editCurb.top_offset_id"/>
    <div class="spacing_btm">
      <Conforming
          :val.sync="editCurb.conforming"
          :conformingId="editCurb.conforming_type_id"
          :label="editCurb.conforming_type"
          :isOshpd="editCurb.is_oshpd"
          @update="(conforming_type_id, is_oshpd, conforming_location_id) => {
                  editCurb.conforming_type_id = conforming_type_id;
                  editCurb.is_oshpd = is_oshpd;
                  editCurb.conforming_location_id = conforming_location_id;
                }"
      />
    </div>
    <div class="spacing_top">
      <input class="btn btn-default" type="button" value="Save" @click="save">
      <input class="btn btn-default" type="button" value="Cancel" @click="cancel">
    </div>
  </div>
</template>

<script>
import RTUTag from './fields/RTUTag';
import Model from "./fields/Model";
import IsCustom from "./fields/IsCustom";
import Applied from "./fields/Applied";
import Sectional from "./fields/Sectional";
import Conforming from './fields/Conforming';
import CurbDepth from './fields/CurbDepth';
import TopOffset from "@/components/businessObjects/tracker/add_item/fields/TopOffset";

export default {
  name: "Vertical",
  components: {
    TopOffset,
    RTUTag,
    Model,
    IsCustom,
    Applied,
    Sectional,
    Conforming,
    CurbDepth
  },
  props: ["curb"],
  data() {
    return {
      editCurb: {
        tracker_invoice_id: 0,
        tag: '',
        cdi_part_id_old: 0,
        old_model_id: 0,
        old_model: '',
        cdi_part_id_new: 0,
        new_model_id: 0,
        new_model: '',
        is_custom: 0,
        applied: 0,
        sectional: 0,
        conforming: 0,
        conforming_type_id: 0,
        conforming_type: "",
        is_oshpd: 0,
        curb_depth_id: 0,
        top_offset_id: 0,
      },
    }
  },
  methods: {
    save: function() {
      if (!this.errors()) {
        this.$emit('save', this.editCurb);
      }
    },
    cancel: function() {
      this.$emit('cancel');
    },
    errors: function() {
      let errors = [];
      if (!parseInt(this.editCurb.old_model_id) && !parseInt(this.editCurb.cdi_part_id_old)) {
        errors.push('Need to select Old Model');
      }
      if (!parseInt(this.editCurb.new_model_id) && !parseInt(this.editCurb.cdi_part_id_new)) {
        errors.push('Need to select New Model');
      }
      if (this.editCurb.conforming == 1 && !this.editCurb.conforming_type_id){
        errors.push('Conforming Stamp Location and Year is required.');
      }
      if (!this.editCurb.curb_depth_id) {
        errors.push('Curb Depth is required.');
      }
      if (!this.editCurb.top_offset_id) {
        errors.push('Top Offset is required.')
      }
      if (errors.length) {
        this.$appStore.errorBox(errors.join("\n"));

        return true;
      }

      return false;
    },
    load: function() {
      for (const field in this.editCurb) {
        this.editCurb[field] = this.curb[field];
      }
    },
  },
  created() {
    if (this.curb.tracker_invoice_id) {
      this.load();
    }
  }
}
</script>

<style scoped>

</style>
// Need to adjust vert curb data to only ever be working with IDs for curb depth and top offset