<template>
    <div class="cdi_text_input object_detail" :title="field.label">
        <label :class="(hasPermission) ? 'cdi_edit' : ''" @click="toggleField">{{ field.label }}</label>
        <DatePicker @click="toggleField" ref="dateInput" :inputAttributes="{class: 'form-control'}" v-model="localVal" />
    </div>
</template>

<script>
import DatePicker from '@/components/utils/DatePicker';
export default {
    name: "DetailsDate",
    components: {
        DatePicker,
    },
    props: ['field', 'val'],
    data() {
        return {
            isActive: false,
            localVal: '',
        }
    },
    created: function () {
      this.localVal = this.val;
    },
    methods: {
        toggleField: function() {
            if (this.hasPermission) {
                this.isActive = !this.isActive;
            }
        },
        save: function() {
            this.$emit('save', {'field': this.field.name, 'val': this.localVal});
            this.isActive = false;
        }
    },
    watch: {
        val: function () {
            this.localVal = this.val;
        },
        triggerVal: function() {
            if (this.isActive && this.localVal === '') {
                return;
            } else {
                if (this.localVal != this.val){
                    this.save();
                }
            }
        }
    },
    computed: {
        hasPermission: function () {
            if (this.field.permission === undefined) {
                return true;
            } else {
                return this.field.permission;
            }
        },
        // Triggers the emit
        triggerVal: function() {
            return this.localVal;
        }
    }
}
</script>

<style scoped>
    .vdpComponent {
        width: 100%;
    }
    .object_detail label{
        border-bottom: none!important;
    }
</style>