import cdiLocationCache from '@/cache/cdiLocation.cache';
import shippingCache from '@/cache/shipping.cache';
import stateCache from '@/cache/state.cache';
import DateHelpers from "@/store/DateHelpers";

export const trackerStore = {
	state: {
		name: 'tracker',
		title: 'Order',
		titlePlural: 'Orders',
		nav: {
			base: 'tracker',
			views: [
				{view: 'details', label: 'Details'},
				{view: 'shipping', label: 'Shipping'},
				{view: 'invoice', label: 'Invoice'},
				{view: 'item', label: 'Items'},
				{view: 'notebook', label: 'Notebook'},
				{view: 'history', label: 'History'}
			]
		},
		search: {
			endPoint: 'trackers',
			formFields: {
				customerPo: {
					val: '',
					qsField: '[strings][distributor_po]',
					label: 'Customer PO#',
					component: 'SearchTextInput'
				},
				jobTag: {
					val: '',
					qsField: '[fstrings][job_tag]',
					label: 'Job Tag',
					component: 'SearchTextInput'
				},
				orderId: {
					val: '',
					qsField: '[numbers][tracker_id]',
					label: 'Order ID',
					component: 'SearchTextInput'
				},
				quoteId: {
					val: '',
					qsField: '[numbers][quote_id]',
					label: 'Quote ID',
					component: 'SearchTextInput'
				},
				orderDateFrom: {
					val: DateHelpers.getXMonthsAgo(12),
					qsField: '[dates][tracker_date_from]',
					label: 'Order Date From',
					component: 'SearchDateInput'
				},
				orderDateTo: {
					val: '',
					qsField: '[dates][tracker_date_to]',
					label: 'Order Date To',
					component: 'SearchDateInput'
				},
				shipDateFrom: {
					val: '',
					qsField: '[dates][actual_ship_from]',
					label: 'Ship Date From',
					component: 'SearchDateInput'
				},
				shipDateTo: {
					val: '',
					qsField: '[dates][actual_ship_to]',
					label: 'Ship Date To',
					component: 'SearchDateInput'
				},
				customer: {
					val: 0,
					qsField: '[numbers][distributor_id]',
					label: 'Customer',
					component: 'SearchObjectInput',
					cdiType: 'search_my_users'
				},
				schShipFrom: {
					val: '',
					qsField: '[dates][scheduled_ship_from]',
					label: 'Sch Ship From',
					component: 'SearchDateInput'
				},
				schShipTo: {
					val: '',
					qsField: '[dates][scheduled_ship_to]',
					label: 'Sch Ship To',
					component: 'SearchDateInput'
				},
				company: {
					val: 0,
					qsField: '[numbers][d.company_id]',
					label: 'Company',
					component: 'SearchTypeaheadInput',
					cdiType: 'company'
				},
				cdiLocation: {
					val: 0,
					qsField: '[numbers][cdi_location_id]',
					label: 'CDI Location',
					component: 'SearchSelectInput',
					options: cdiLocationCache.CDI_LOCATIONS,
					toggleHide: true,
					defaultVal: 0,
				},
				contractorPo: {
					val: '',
					qsField: '[strings][contractor_po]',
					label: 'Contractor PO#',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				cdiPartNum: {
					val: '',
					qsField: '[fstrings][cdi_part_num]',
					label: 'CDI Part #',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				regionalSalesRep: {
					val: 0,
					qsField: '[subquery][outside_sales]',
					label: 'Regional Sales Rep',
					component: 'SearchTypeaheadInput',
					cdiType: 'sales_employee',
					toggleHide: true,
					defaultVal: 0,
				},
				salesSupportRep: {
					val: 0,
					qsField: '[subquery][inside_sales]',
					label: 'Sales Support Rep',
					component: 'SearchTypeaheadInput',
					cdiType: 'sales_employee',
					toggleHide: true,
					defaultVal: 0,
				},
				deliveryDateFrom: {
					val: '',
					qsField: '[dates][receive_ship_from]',
					label: 'Delivery Date From',
					component: 'SearchDateInput',
					toggleHide: true,
					defaultVal: '',
				},
				deliveryDateTo: {
					val: '',
					qsField: '[dates][receive_ship_to]',
					label: 'Delivery Date to',
					component: 'SearchDateInput',
					toggleHide: true,
					defaultVal: '',
				},
				schDeliveryDateFrom: {
					val: '',
					qsField: '[dates][sch_receive_ship_from]',
					label: 'Sch. Delivery From',
					component: 'SearchDateInput',
					toggleHide: true,
					defaultVal: '',
				},
				schDeliveryDateTo: {
					val: '',
					qsField: '[dates][sch_receive_ship_to]',
					label: 'Sch. Delivery To',
					component: 'SearchDateInput',
					toggleHide: true,
					defaultVal: '',
				},
				salesOrderNum: {
					val: '',
					qsField: '[fstrings][sales_order_num]',
					label: 'Sales Order #',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				trackingProNum: {
					val: '',
					qsField: '[tracking_pro_num]',
					label: 'Tracking Pro#',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				trucking: {
					val: 0,
					qsField: '[numbers][trucking_options_id]',
					label: 'Trucking',
					component: 'SearchSelectInput',
					options: shippingCache.SHIPPING_OPTIONS,
					toggleHide: true,
					defaultVal: 0,
				},
				Shipper: {
					val: 0,
					qsField: '[numbers][shipping_co_id]',
					label: 'Shipper',
					component: 'SearchTypeaheadInput',
					cdiType: 'shipping_co',
					toggleHide: true,
					defaultVal: 0,
				},
				contactName: {
					val: '',
					qsField: '[strings][contact_name]',
					label: 'Contact Name',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				contactPhone: {
					val: '',
					qsField: '[strings][contact_phone]',
					label: 'Contact Phone',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				shipToName: {
					val: '',
					qsField: '[fstrings][sa.address_name]',
					label: 'Ship To Name',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				shipToAddress: {
					val: '',
					qsField: '[fstrings][sa.address]',
					label: 'Ship To Address',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				shipToCity: {
					val: '',
					qsField: '[strings][sa.city]',
					label: 'Ship To City',
					component: 'SearchTypeaheadInput',
					cdiType: 'city',
					toggleHide: true,
					defaultVal: '',
				},
				shipToState: {
					val: 0,
					qsField: '[strings][sa.state]',
					label: 'Ship To State',
					component: 'SearchSelectInput',
					options: stateCache.STATES,
					toggleHide: true,
					defaultVal: 0,
				},
				shipToZip: {
					val: '',
					qsField: '[strings][sa.zip]',
					label: 'Ship To Zip',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				shipInstructions: {
					val: '',
					qsField: '[fstrings][shipto_instructions]',
					label: 'Ship Instructions',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				totalCost: {
					val: '',
					qsField: '[numbers][total_cost]',
					label: 'Total Cost',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				totalAmount: {
					val: '',
					qsField: '[numbers][total_amount]',
					label: 'Total Amount',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				customerAddress: {
					val: '',
					qsField: "[fstrings][da.address]",
					label: 'Customer Address',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				customerCity: {
					val: '',
					qsField: '[fstrings][da.city]',
					label: 'Customer City',
					component: 'SearchTypeaheadInput',
					cdiType: 'city',
					toggleHide: true,
					defaultVal: '',
				},
				customerState: {
					val: 0,
					qsField: '[strings][da.state]',
					label: 'Customer State',
					component: 'SearchSelectInput',
					options: stateCache.STATES,
					toggleHide: true,
					defaultVal: 0,
				},
				customerZip: {
					val: '',
					qsField: '[fstrings][da.zip]',
					label: 'Customer Zip',
					component: 'SearchTextInput',
					toggleHide: true,
					defaultVal: '',
				},
				measured: {
					val: '',
					qsField: '[flags][measured]',
					label: 'Measured',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				remake: {
					val: '',
					qsField: '[flags][remake]',
					label: 'Remake',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				onHold: {
					val: '',
					qsField: '[flags][hold]',
					label: 'On Hold',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				truckedBooked: {
					val: '',
					qsField: '[flags][truck_booked]',
					label: 'Trucked Booked',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				requiredDeliveryDate: {
					val: '',
					qsField: '[flags][req_delivery_date]',
					label: 'Required Delivery Date',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				invalidDelivery: {
					val: '',
					qsField: '[flags][invalidship]',
					label: 'Invalid Delivery',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},

				validShipTo: {
					val: '',
					qsField: '[isset][sa.validity]',
					label: 'Valid Shipto',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				invalidShipTo: {
					val: '',
					qsField: '[isnot][sa.valdity]',
					label: 'Invalid Shipto',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				paid: {
					val: '',
					qsField: '[flags][paid]',
					label: 'Paid',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				unpaid: {
					val: '',
					qsField: '[flags][unpaid]',
					label: 'Unpaid',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				sentInvoice: {
					val: '',
					qsField: '[flags][sent]',
					label: 'Sent Invoice',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				unsentInvoice: {
					val: '',
					qsField: '[flags][unsent]',
					label: 'Unsent Invoice',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				canceled: {
					val: '',
					qsField: '[canceled]',
					label: 'Canceled',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				shippedLate: {
					val: '',
					qsField: '[flags][shiplate]',
					label: 'Shipped Late',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				showPricing: {
					val: '1',
					qsField: '[show_price]',
					label: 'Show Pricing',
					component: "SearchCheckboxInput"
				},
                searchIndex: {val: 0, qsField: '[search_index]'},
			},
			resultFields: {
				tracker_id: {header: 'Order', link: 'tracker'},
				f_tracker_date: {header: 'Date', link: ''},
				distributor: {header: 'Customer', link: ''},
				company_name: {header: 'Company', link: ''},
				distributor_po: {header: 'Customer PO', link: ''},
				job_tag: {header: 'Job Tag', link: ''},
				scheduled_ship: {header: 'Sch. Ship', link: ''},
				actual_ship: {header: 'Actual', link: ''},
				total_amount: {header: 'Total', link: ''}
			}
		},
		object: {}
	}
}