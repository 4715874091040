<template>
    <div class="col-xs-12">
        <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
            <label>
                <input type="radio" v-model="eave_peak_selection" :value="cache.curbTypeCache.LONG_SIDE_PEAK" @change="$emit('update:val', eave_peak_selection)"> Long side pitch - peak
            </label>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
            <label>
                <input type="radio" v-model="eave_peak_selection" :value="cache.curbTypeCache.SHORT_SIDE_PEAK" @change="$emit('update:val', eave_peak_selection)"> Short side pitch - peak
            </label>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
            <label>
                <input type="radio" v-model="eave_peak_selection" :value="cache.curbTypeCache.LONG_SIDE_EAVE" @change="$emit('update:val', eave_peak_selection)"> Long side pitch - eave
            </label>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
            <label>
                <input type="radio" v-model="eave_peak_selection" :value="cache.curbTypeCache.SHORT_SIDE_EAVE" @change="$emit('update:val', eave_peak_selection)"> Short side pitch - eave
            </label>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
            <label>
                <input type="radio" v-model="eave_peak_selection" :value="cache.curbTypeCache.MULTI_PITCH_PEAK_EAVE" @change="$emit('update:val', eave_peak_selection)"> Multi Pitched
            </label>
        </div>
    </div>
</template>

<script>
import curbTypeCache from '@/cache/curbType.cache';
export default {
    name: "EavePeak",
    props: ['val'],
    data() {
        return {
            eave_peak_selection: '',
            cache: {
                curbTypeCache
            },
        }
    },
    created() {
        this.eave_peak_selection = this.val;
    },
}
</script>

<style scoped>

</style>