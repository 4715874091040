<template>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
        <label>RTU Tag</label>
        <input class="form-control" type="text" v-model="tag" @input="$emit('update:val', tag)">
    </div>
</template>

<script>
export default {
    name: "RTUTag",
    props: ['val'],
    data() {
        return {
            tag: '',
        }
    },
    created() {
        this.tag = this.val;
    },
}
</script>

<style scoped>

</style>