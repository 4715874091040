<template>
    <div>
        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>{{ label }}</label>
            <input type="text" v-model="ft"  @input="$emit('update:val', ft)" class="form-control" >
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label></label>
            <select v-model="inch_id" class="form-control" @change="$emit('update:val2', inch_id)">
                <option v-for="inches in cache.curbTypeCache.INCHES_FRACTIONS" :key="inches[0]" :value="inches[0]">{{ inches[1] }}</option>
            </select>
        </div>
    </div>
</template>

<script>
import curbTypeCache from  '@/cache/curbType.cache.js';
export default {
    name: "Dimension",
    props: ['label', 'val', 'val2'],
    data() {
        return {
            ft: 6,
            inch_id: 0,
            cache: {
                curbTypeCache
            },
        }
    },
    created() {
        this.ft = this.val;
        this.inch_id = this.val2;
    },
}
</script>

<style scoped>

</style>