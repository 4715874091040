var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"spacing_btm"},[_c('input',{staticClass:"lock btn btn-default",attrs:{"type":"button","value":"Send Notification"},on:{"click":_vm.sendNotification}}),_c('input',{staticClass:"btn btn-default",attrs:{"type":"button","value":"Bill of Lading"},on:{"click":_vm.openBillOfLading}}),_c('input',{directives:[{name:"show",rawName:"v-show",value:(_vm.state.object.data.shipping_co_id === 23),expression:"state.object.data.shipping_co_id === 23"}],staticClass:"btn btn-default",attrs:{"type":"button","value":"Auto Route"},on:{"click":_vm.autoRoute}}),_c('input',{directives:[{name:"show",rawName:"v-show",value:(_vm.appState.user.userTypeID === 4),expression:"appState.user.userTypeID === 4"}],staticClass:"btn btn-default",attrs:{"type":"button","value":"Resend (3PL)"},on:{"click":_vm.resetShipper}})]),_c('h3',[_vm._v("Shipment Addresses")]),_c('ShipmentAddresses',{attrs:{"values":{
                shipperAddress: {
                    addressName: _vm.stateData.shipper_name,
                    address: _vm.stateData.shipper_address,
                    city: _vm.stateData.shipper_city,
                    state: _vm.stateData.shipper_state,
                    zip: _vm.stateData.shipper_zip
                },
                shipToAddress: {
                    addressName: _vm.stateData.shipto_name,
                    address: _vm.stateData.shipto_address,
                    city: _vm.stateData.shipto_city,
                    state: _vm.stateData.shipto_state,
                    zip: _vm.stateData.shipto_zip
                }
            },"permission":_vm.lockRules && !_vm.creditCardLock}}),_c('h3',[_vm._v("Shipping Details")]),_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"row panel-body details_container"},[_c('h4',{staticStyle:{"margin-left":"15px"}},[_vm._v("Call Ahead")]),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsCheckbox',{attrs:{"val":_vm.stateData.call_ahead,"field":{
                        name: 'call_ahead',
                        label: 'Call Ahead',
                    }},on:{"save":_vm.save}})],1),(_vm.stateData.call_ahead)?_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.stateData.contact_name,"field":{
                        name: 'contact_name',
                        label: 'Contact Name',
                        permission: _vm.lockRules
                    }},on:{"save":_vm.save}})],1):_vm._e(),(_vm.stateData.call_ahead)?_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.stateData.contact_phone,"field":{
                        name: 'contact_phone',
                        label: 'Contact Phone',
                        permission: _vm.lockRules
                    }},on:{"save":_vm.save}})],1):_vm._e(),(_vm.stateData.call_ahead)?_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.stateData.contact_email,"field":{
                        name: 'contact_email',
                        label: 'Contact Email',
                        permission: _vm.lockRules
                    }},on:{"save":_vm.save}})],1):_vm._e(),(_vm.stateData.call_ahead)?_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.stateData.contact_email_cc,"field":{
                        name: 'contact_email_cc',
                        label: 'Contact Email CC',
                        permission: _vm.lockRules
                    }},on:{"save":_vm.save}})],1):_vm._e(),(_vm.stateData.call_ahead)?_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsCheckbox',{attrs:{"val":_vm.stateData.call_ahead_complete,"field":{
                        name: 'call_ahead_complete',
                        label: 'Call Ahead Completed',
                    }},on:{"save":_vm.saveCallAhead}})],1):_vm._e()])]),_c('div',{staticClass:"panel panel-default "},[_c('div',{staticClass:"row panel-body details_container"},[(_vm.customs.canada)?_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsTypeahead',{attrs:{"val":_vm.stateData.shipping_co_id,"field":{
                        name: 'customs_bill_to',
                        display: _vm.customs.customs_bill_to,
                        label: 'Customs Bill To',
                        cdiType: 'bill_to',
                        permission: _vm.lockRules,
                        params: {'params': _vm.stateData.company_id}
                    }},on:{"save":_vm.saveCustomsBillTo}})],1):_vm._e(),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsTextarea',{attrs:{"val":_vm.stateData.shipto_instructions,"field":{
                        name: 'shipto_instructions',
                        label: 'Shipping Instructions',
                        permission: _vm.lockRules
                    }},on:{"save":_vm.save}})],1),(!_vm.stateData.call_ahead)?_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.stateData.contact_name,"field":{
                        name: 'contact_name',
                        label: 'Contact Name',
                        permission: _vm.lockRules
                    }},on:{"save":_vm.save}})],1):_vm._e(),(!_vm.stateData.call_ahead)?_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.stateData.contact_phone,"field":{
                        name: 'contact_phone',
                        label: 'Contact Phone',
                        permission: _vm.lockRules
                    }},on:{"save":_vm.save}})],1):_vm._e(),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsDate',{attrs:{"val":_vm.stateData.scheduled_ship,"field":{
                        name: 'scheduled_ship',
                        label: 'Scheduled Ship',
                        permission: _vm.lockRules
                    }},on:{"save":_vm.openScheduledShipModal}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsDate',{attrs:{"val":_vm.stateData.actual_ship,"field":{
                        name: 'actual_ship',
                        label: 'Actual Ship',
                        permission: (_vm.lockRules || _vm.stateData.permissions.ship_date)
                    }},on:{"save":_vm.saveActualShip}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsDate',{attrs:{"val":(_vm.stateData.hold || _vm.stateData.credit_hold) ? 'On Hold' : _vm.stateData.sch_receive_ship,"field":{
                        name: 'sch_receive_ship',
                        label: 'Scheduled Delivery',
                        permission: (_vm.lockRules || _vm.stateData.permissions.scheduled_ship)
                    }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsDate',{attrs:{"val":_vm.stateData.receive_ship,"field":{
                        name: 'receive_ship',
                        label: 'Delivered'
                    }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsCheckbox',{attrs:{"val":_vm.stateData.req_delivery_date,"field":{
                        name: 'req_delivery_date',
                        label: 'Required Delivery'
                    }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsCheckbox',{attrs:{"val":_vm.stateData.fully_crate,"field":{
                        name: 'fully_crate',
                        label: 'Fully Crate'
                    }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsTypeahead',{attrs:{"val":_vm.stateData.shipping_co_id,"field":{
                        name: 'shipping_co_id',
                        display: _vm.stateData.shipping_co,
                        label: 'Shipper',
                        link: 'Shipper',
                        cdiType: 'shipping_co'
                    }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsSelect',{attrs:{"val":_vm.stateData.trucking_options_id,"field":{
                        name: 'trucking_options_id',
                        label: 'Trucking',
                        options: _vm.cache.ShippingCache.SHIPPING_OPTIONS
                    }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsCheckbox',{attrs:{"val":_vm.stateData.flatbed,"field":{
                        name: 'flatbed',
                        label: 'Flatbed',
                        permission: _vm.lockRules
                    }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsSelect',{attrs:{"val":_vm.stateData.ship_account_num,"field":{
                        name: 'ship_account_num',
                        label: 'Ship Acc',
                        options: [
                            ['', 'None'],
                            [4035, 4035],
                            [4040, 4040],
                            [4045, 4045],
                            [4050, 4050],
                            [4055, 4055]
                        ],
                        excludeDefault: true
                    }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.stateData.BOL_description,"field":{
                        name: 'BOL_description',
                        label: 'BoL Description',
                        permission: _vm.lockRules
                    }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.stateData.truck_booked,"field":{
                        name: 'truck_booked',
                        label: 'Booked',
                        permission: _vm.lockRules
                    }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.stateData.tracking_pro_num,"field":{
                        name: 'tracking_pro_num',
                        label: 'Tracking PRO #',
                        url: ("" + (_vm.stateData.url) + (_vm.stateData.tracking_pro_num)),
                        tag: (_vm.stateData.epro_override) ? 'Override' : ''
                    }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsSelect',{attrs:{"val":_vm.stateData.can_receive,"field":{
                        name: 'can_receive',
                        label: 'Can Receive',
                        options: [
                            [1, 'Yes'],
                            [2, 'No'] ]
                    }},on:{"save":_vm.save}})],1),(_vm.stateData.ship_control)?_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsStatic',{attrs:{"val":_vm.stateData.shipping_status,"field":{
                        name: 'shipping_status',
                        label: 'Ship Status'
                    }},on:{"save":_vm.save}})],1):_vm._e(),(!_vm.stateData.ship_control)?_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsSelect',{attrs:{"val":_vm.stateData.shipping_status,"field":{
                        name: 'shipping_status',
                        label: 'Ship Status',
                        options: [
                            ['In Production', 'In Production'],
                            ['Intransit', 'Intransit'],
                            ['At Delivery Terminal', 'At Delivery Terminal'],
                            ['Out for Delivery', 'Out for Delivery'],
                            ['Delivered', 'Delivered']
                        ]
                    }},on:{"save":_vm.save}})],1):_vm._e(),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsStatic',{attrs:{"val":_vm.stateData.shipping_status_date,"field":{
                        name: 'shipping_status_date',
                        label: 'Ship Status Date'
                    }},on:{"save":_vm.save}})],1)])]),_c('div',[_c('h3',[_vm._v("Freight Costs")]),_c('div',{staticClass:"row panel-body details_container"},[_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.stateData.freight_cost,"field":{
                        name: 'freight_cost',
                        label: 'Freight Cost',
                        permission: !_vm.creditCardLock
                    }},on:{"save":_vm.saveFreightCost}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_vm._m(0),_c('div',{staticClass:"margin_bottom"},[_vm._v(" $"+_vm._s(this.stateData.late_detention_fee)+" : "+_vm._s(this.freightReason)+" ")]),(!_vm.creditCardLock)?_c('button',{staticClass:"btn btn-default",on:{"click":_vm.openAdditionalFreightCostModal}},[_vm._v("Add Additional Freight Cost")]):_vm._e()]),_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-6 form-group"},[_c('DetailsCheckbox',{attrs:{"val":_vm.stateData.shipping_paid,"field":{
                    name: 'shipping_paid',
                    label: 'Paid',
                }},on:{"save":_vm.save}})],1)])]),_c('ScheduledShipModal',{ref:"scheduledShipModal",on:{"save":_vm.saveScheduledShip}}),_c('BillOfLadingModal',{ref:"billOfLadingModal",attrs:{"trackerID":_vm.stateData.tracker_id}}),(_vm.showConfirmEmailModal)?_c('Modal',{attrs:{"heading":"Order Email"},on:{"close":_vm.closeEmailModal},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',[_c('p',{staticClass:"spacing_btm"},[_vm._v("Email Customer a copy of their order?")]),_c('label',[_vm._v("Notes:")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmBoxNotes),expression:"confirmBoxNotes"}],staticClass:"form-control",attrs:{"rows":"5"},domProps:{"value":(_vm.confirmBoxNotes)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmBoxNotes=$event.target.value}}})])]},proxy:true},{key:"footer",fn:function(){return [_c('div',[_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.sendEmailConfirmation}},[_vm._v("Yes")]),_c('button',{staticClass:"btn btn-default",on:{"click":_vm.closeEmailModal}},[_vm._v("No")])])]},proxy:true}],null,false,1471889923)}):_vm._e(),(_vm.additionalFreightCostModal)?_c('Modal',{attrs:{"heading":"Additional Freight Cost"},on:{"close":_vm.closeAdditionalFreightCostModal},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',[_c('DetailsSelect',{ref:"feeTypeId",class:{'red-text': !_vm.saveSuccess},attrs:{"val":_vm.stateData.fee_type_id,"field":{
                        name: 'fee_type_id',
                        label: _vm.saveSuccess ? '** Additional Freight Cost Reason' : '** This field is required **',
                        options: _vm.cache.ShippingCache.FEE_TYPES
                    }},on:{"save":_vm.saveFees}}),(_vm.showFee)?_c('DetailsText',{ref:"lateDetentionFee",attrs:{"val":_vm.stateData.late_detention_fee,"field":{
                        name: 'late_detention_fee',
                        label: 'Additional Freight Cost'
                    }},on:{"save":_vm.saveFees}}):_vm._e()],1)]},proxy:true}],null,false,2713240576)}):_vm._e(),_c('Toast',{on:{"toastAction":_vm.openEmailModal}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',[_vm._v("Additional Freight Cost")])])}]

export { render, staticRenderFns }