<template>
    <transition name="modal_fade">
        <div class="modal_backdrop">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">

                <div class="modal_header">
                    {{heading}}
                    <button type="button" class="modal_btn_close" @click="close(false)" aria-label="Close modal"><span class="glyphicon glyphicon-remove"></span></button>
                </div>

                <div class="modal_body">
                    {{body}}

                    <div class="form-group">
                        <label>{{ fieldLabel }}</label>
                        <textarea class="dialog-textarea form-control" v-model='fieldVal' :placeholder="placeholder"></textarea>
                    </div>
                </div>

                <div class="modal_footer">
                    <button type="button" class="btn btn-primary" @click="close(true)" aria-label="Close modal">Yes</button>
                    <button type="button" class="btn btn-default" @click="close(false)" aria-label="Close modal">No</button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "ConfirmBox",
    data() {
        return {
            fieldVal: ''
        }
    },
    props: ['heading', 'body', 'placeholder', 'fieldLabel'],
    methods: {
        close: function (confirm) {
            let response = {
                confirmed: confirm,
                val:  this.fieldVal
            }
            this.$emit('confirm', response);
        }
    }
}
</script>

<style scoped>

</style>