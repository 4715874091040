<template>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
        <label>Height</label>
        <input class="form-control" type="text" v-model="base_height" @input="$emit('update:val', base_height)">
    </div>
</template>

<script>
export default {
    name: "RTUTag",
    props: ['val'],
    data() {
        return {
            base_height: 0,
        }
    },
    created() {
        this.base_height = this.val;
    },
}
</script>

<style scoped>

</style>