<template>
    <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
        <label>
            <input :true-value="1" :false-value="0" type="checkbox" v-model="applied" @change="$emit('update:val', applied)"> Custom
        </label>
    </div>
</template>

<script>
export default {
    name: "Applied",
    props: ['val'],
    data() {
        return {
            applied: 0,
        }
    },
    created() {
        this.applied = this.val ? 1 : 0;
    },
}
</script>

<style scoped>

</style>