<template>
    <div>
        <h2>Remake For Order #{{ trackerId }}</h2>
        <div v-show='!remake'>
            <form>
                <table class='table'>
                    <tbody>
                    <tr><td><input type=radio v-model='remakeOption' value="11"></td><td><label>Order Entry Remake</label><br>Copy Order, Flag as Commission remake, and set invoice to $0</td></tr>
                    <tr><td><input type=radio v-model='remakeOption' value="31" ></td><td><label>Sales Remake</label><br>Copy Order, Flag as Commission remake, and set invoice to $0</td></tr>
                    <tr><td><input type=radio v-model='remakeOption' value="12" ></td><td><label>Design Remake</label><br>Copy Order, Flag as Commission remake, and set invoice to $0</td></tr>
                    <tr><td><input type=radio v-model='remakeOption' value="13" ></td><td><label>Production Remake</label><br>Copy Order, Flag as Commission remake, and set invoice to $0</td></tr>
                    <tr><td><input type=radio v-model='remakeOption' value="14" ></td><td><label>Freight Remake</label><br>Copy Order, Flag as Freight remake, and set invoice to $0</td></tr>
                    <tr><td><input type=radio v-model='remakeOption' value="15" ></td><td><label>Customer Remake</label><br>Copy Order, Flag as Customer remake, and copy full invoice</td></tr>
                    <tr><td><input type=radio v-model='remakeOption' value="16" ></td><td><label>Reorder</label><br>Copy Order, Flag as Reorder, and copy full invoice</td></tr>
                    </tbody>
                </table>
                <div class="row">
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group"><label>New Scheduled Ship Date</label><DatePicker v-model='scheduledShip' :input-attributes="{class: 'form-control'}" /></div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group"><label>New PO</label><input v-model='distributorPo' class="form-control"></div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                        <label><input type='checkbox' :true-value='1' :false-value=null name='params[send_confirmation_email]' v-model='sendConfirmationEmail'> Send confirmation email</label>
                    </div>
                </div>
                <div class='cdi_button'>
                    <input @click='remakeOrder' type=button value='Remake Order' class="btn btn-default">
                    <input @click='cancelRemake' type=button value='Cancel' class="btn btn-default">
                </div>
            </form>
        </div>
        <div v-show='remake'>
            <p>Order Remade! <span v-show='remake.duplicate_po' class='text-warning'>Warning! Duplicate PO</span></p>
            <p>The order number for the remake is #{{ remake.tracker_id }}</p>
            <p><router-link :to='`/tracker/${remake.tracker_id}`'>Continue to remake order</router-link></p>
            <p><a class="pointer" @click="cancelRemake">Return to current order</a></p>
        </div>
    </div>
</template>

<script>
import DatePicker from "@/components/utils/DatePicker";
import { store } from "@/store/BusinessObject.store";
export default {
    name: 'Remake',
    components: {DatePicker},
    props: ['trackerId'],
    data() {
        return {
            state: store.state,
            remakeOption: 11,
            scheduledShip: '',
            distributorPo: '',
            sendConfirmationEmail: 1,
            remake: false,
        }
    },
    methods: {
        remakeOrder: function() {
            let params = {
                'params[remake_option]': this.remakeOption,
                'params[scheduled_ship]': this.scheduledShip,
                'params[distributor_po]': this.distributorPo,
                'params[send_confirmation_email]': this.sendConfirmationEmail
            };
            store.api('remake', params).then( (data) => {
                if (data.opt === 'get') {
                    this.resetForm();
                    this.remake = data;
                }
            });
        },
        cancelRemake: function() {
            this.resetForm();
            this.state.currentView = 'details'
        },
        resetForm: function() {
            this.remakeOption = 11;
            this.scheduledShip = '';
            this.distributorPo = '';
            this.sendConfirmationEmail = 1;
            this.remake = false;
        }
    },
    computed: {
        view: function() {
            return this.state.currentView;
        }
    },
    watch: {
        view: function() {
            if (this.view === 'remake') {
                this.resetForm();
            }
        }
    }
}
</script>