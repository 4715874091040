<template>
    <div class="row">
        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Task</label>
            <select class='form-control' v-model='task'>
                <option value='0'>None</option>
                <option v-for='[index, option] in cache.taskCache.JOBCOSTING_TASKS' :key='index' :value='index'>{{ option }}</option>
            </select>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Start Date</label>
            <DatePicker v-model="startDate" :input-attributes="{class: 'form-control'}" :pickTime="true" :format="'MM/DD/YYYY hh:mm'"></DatePicker>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Mins</label>
            <input type=text v-model='mins' class="form-control">
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Employee</label>
            <typeahead cdi_type='employee' :ID.sync='employee' />
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
            <label>
                <input type=checkbox v-model='complete' :true-value='1' :false-value='0'> Complete
            </label>
        </div>
        <div class='col-sm-12'>
            <div>Add to {{curb.line}}{{curb.qty}} {{curb.nomenclature}}</div>
            <div>
                <button class='btn btn-default' @click='addTask'>Add Job Costing</button>
                <button class='btn btn-default' @click='close'>Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "@/store/BusinessObject.store";
import DatePicker from "@/components/utils/DatePicker";
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import curbTypeCache from "@/cache/curbType.cache";
import taskCache from '@/cache/task.cache.js';

export default {
    name: 'NewTask',
    components: {Typeahead, DatePicker},
    props: ['curb'],
    data() {
        return {
            state: store.state,
            task: 0,
            startDate: '',
            mins: 0,
            employee: 0,
            complete: 0,
            cache: {
                curbTypeCache,
                taskCache
            }
        }
    },
    methods: {
        addTask: function() {
            store.customApi(`tracker_curb/add_labor/${ this.curb.tracker_invoice_id }`, {
                'params[task_id]': this.task,
                'params[start_time]': this.startDate,
                'params[t_minutes]': this.mins,
                'params[user_id]': this.employee,
                'params[complete]': this.complete
            });
            this.close();
        },
        close: function() {
            this.state.currentView = 'item';
        }
    },

}

</script>