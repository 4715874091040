<template>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
        <label>Pitch</label>
        <select v-model="pitch_id" class="form-control" @change="$emit('update:val', pitch_id)">
            <option v-for="pitch in pitches" :key="pitch[0]" :value="pitch[0]">{{ pitch[1] }}</option>
        </select>
    </div>
</template>

<script>
import curbTypeCache from  '@/cache/curbType.cache.js';
export default {
    name: "Pitch",
    props: ['val', 'curbTypeID'],
    data() {
        return {
            pitch_id: 0,
            cache: {
                curbTypeCache
            },
        }
    },
    computed: {
        pitches: function() {
            if (this.curbTypeID === this.cache.curbTypeCache.STRAIGHT_CURB) {
                return [this.cache.curbTypeCache.PITCHES[0]];
            } else if (this.curbTypeID === this.cache.curbTypeCache.ADJUSTABLE_PITCHED_CURB) {
                return [[9, 'Adjustable']];
            } else {
                return this.cache.curbTypeCache.PITCHES;
            }
        }
    },
    created() {
        this.pitch_id = this.val;
    },
}
</script>

<style scoped>

</style>