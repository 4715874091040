<template>
    <div v-if="$appStore.hasRole([$cdiVars.CDI_ROLE_ACCOUNTING, $cdiVars.CDI_ROLE_LEADERSHIP])" class="panel panel-default spacing_top">
        <div class="panel-heading"><strong>Pricing Utilities</strong></div>
        <div class="row panel-body details_container">
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <div class="object_detail" title='Recalculate Total'>
                    <input type="button" class="cdi_button form-control" value="Recalculate Total" @click="$emit('recalculateTotal')" />
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <div class="object_detail" title='Recalculate Total'>
                    <input type="button" class="cdi_button form-control" value="Set Zero Surcharge" @click="$emit('zeroSurcharge')">
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <div class="object_detail" title='Recalculate Total'>
                    <input type="button" class="cdi_button form-control" value="Apply Surcharge" @click="$emit('applySurcharge')">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PricingUtilities",
}
</script>

<style scoped>

</style>