<template>
    <div>
        <h3>Skid</h3>
        <div v-if="skids.noskids && skids.noskids.length > 0">
            <div class="row">
                <div class="col-xs-12 form-group">
                    <div>
                        <label>Item(s)</label>
                        <div>
                            <select v-model="trackerInvoiceId" multiple :size="skidLength" class="form-control">
                                <option v-for="(noSkid, index) in skids.noskids" v-bind:key="index" :value="noSkid.tracker_invoice_id">{{  noSkid.line  }}: {{  noSkid.custom_disp  }} {{  noSkid.nomenclature }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <h4>Or</h4>

            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Skid</label><input type=text v-model="trackerSkidId" class="form-control">
                </div>
            </div>

            <input type="button" value="Skid Item" @click="skidCurbModal" class="btn btn-default">
        </div>
        <div>
            <div class="table-responsive">
                <table class="table spacing_top">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Skid</th>
                        <th>L&nbsp;x&nbsp;W&nbsp;x&nbsp;H</th>
                        <th>Weight</th>
                        <th>Class</th>
                        <th>Items</th>
                    </tr>
                    </thead>
                    <tbody >
                        <tr v-for="(skid, index) in skids.skids" v-bind:key="index">
                            <td><span class="glyphicon glyphicon-trash cdi_link" @click="deleteModal(skid.tracker_skid_id)"></span></td>
                            <td class="cdi_link" @click="skidIdHelp(skid.tracker_skid_id)">{{ skid.tracker_skid_id }}</td>
                            <td>{{ skid.skid_length }} x {{ skid.skid_width }} x {{ skid.skid_height }}</td>
                            <td>{{ skid.skid_weight }}</td>
                            <td>{{ skid.skid_class }}</td>
                            <td class="max_col_width">
                                <ul class="box_list">
                                    <li v-for="(curb, curbIndex) in skid.curbs" v-bind:key="curbIndex">{{ curb.line }}: {{ curb.nomenclature }} <span class="glyphicon glyphicon-remove pointer cdi_link" @click="unskidCurbModal(curb.tracker_invoice_id)"></span></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ConfirmBox :heading="'Delete skid?'" :body="'Would you like to delete this skid?'" @close="deleteSkid" v-if="showDeleteModal" />
        <ConfirmBox :heading="'Remove from skid?'" :body="'Would you like to remove this curb from the skid?'" @close="unskidCurb" v-if="showUnskidModal" />
        <SkidModal @close="closeModal" :dimensions="dimensions" :skidId="trackerSkidId" :trackerInvoiceId="trackerInvoiceId" v-show="showSkidModal" @refreshSkid="refreshSkid" />
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store';
import SkidModal from './SkidModal'
import ConfirmBox from '@/components/utils/ConfirmBox';

export default {
    name: 'Skid',
    data() {
        return {
            trackerInvoiceId: [],
            state: store.state,
            showSkidModal: false,
            trackerSkidId: '',
            skidToDelete: 0,
            showDeleteModal: false,
            curbToRemove: 0,
            showUnskidModal: false,
            dimensions: {
                height: 0,
                width: 0,
                length: 0,
                weight: 0
            }
        }
    },
    props: ['skids'],
    components: {ConfirmBox, SkidModal},
    methods: {
        unskidCurbModal: function(trackerInvoiceId) {
            this.showUnskidModal = true;
            this.curbToRemove = trackerInvoiceId;
        },
        unskidCurb: function(confirmed) {
            if (confirmed) {
                store.api('unskid_curb', {'params' : this.curbToRemove})
                .then(result => {
                    this.state.skids = result;
                    this.refreshSkid();
                })
            }
            this.showUnskidModal = false;
        },
        deleteModal: function(trackerSkidId) {
            this.skidToDelete = trackerSkidId;
            this.showDeleteModal = true;
        },
        deleteSkid: function(confirmed) {
            if (confirmed) {
                store.api('delete_skid', {'params': this.skidToDelete})
                .then(result => {
                    this.state.skids = result;
                    this.refreshSkid();
                });
            }
            this.showDeleteModal = false;
            this.skidToDelete = 0;
        },
        skidCurbModal: function() {
            if (this.trackerInvoiceId.length > 0) {
                if (this.isValidSkid()) {
                    if (this.trackerInvoiceId.length > 1) {
                        for (let i = 0; i < this.trackerInvoiceId.length; i++) {
                            this.dimensions.weight += this.skids.noskids[i].skid_weight;
                        }
                    } else {
                        this.dimensions.height = this.skids.noskids[0].skid_height;
                        this.dimensions.width = this.skids.noskids[0].skid_width;
                        this.dimensions.length = this.skids.noskids[0].skid_length;
                        this.dimensions.weight = this.skids.noskids[0].skid_weight;
                    }
                    this.showSkidModal = true;
                }  else {
                    this.$appStore.state.errorText = 'Invalid skid. Please select a valid skid.';
                }
            } else {
                this.$appStore.state.errorText = 'Must select at least one curb to skid.';
            }
        },
        closeModal: function() {
            this.showSkidModal = false;
            this.trackerSkidId = '';
            this.resetDimensions();
        },
        skidIdHelp: function(skidId) {
            this.trackerSkidId = skidId;
        },
        isValidSkid: function() {
            if (this.trackerSkidId === '') {
                return true;
            }
            for (let i = 0; i < this.skids.skids.length; i++) {
                if (this.skids.skids[i].tracker_skid_id == this.trackerSkidId) {
                    return true;
                }
            }
            return false;
        },
        refreshSkid: function() {
            this.$emit('refreshSkid');
        },
        resetDimensions: function() {
            this.dimensions = {
                height: 0,
                width: 0,
                length: 0,
                weight: 0
            }
        },

    },
    computed: {
        skidLength: function() {
            if (this.skids != undefined) {
                return (this.skids.noskids.length > 15) ? 15 : this.skids.noskids.length;
            } else {
                return 1
            }
        }
    }
}

</script>

<style scoped>
    .max_col_width {
        max-width: 450px;
    }
</style>