<template>
    <div class="spacing_btm">
        <b>Accessories on RTU</b>
        <div v-for="[rtuAccessoryID, rtuAccessory] in cache.curbTypeCache.RTU_ACCESSORIES" :key="rtuAccessoryID">
            <label><input type="checkbox" v-model="rtu_accessories" :value="rtuAccessoryID" @change="$emit('update:val', rtu_accessories)"> {{ rtuAccessory }}</label>
        </div>
    </div>
</template>

<script>
import curbTypeCache from '@/cache/curbType.cache';
export default {
    name: "Accessories",
    props: ['val'],
    data() {
        return {
            rtu_accessories: [],
            cache: {
                curbTypeCache
            },
        }
    },
    created() {
        this.rtu_accessories = this.val;
    },
}
</script>

<style scoped>

</style>