<template>
    <div>
        <div class="form-inline spacing_btm">
            <input class='btn btn-default' type="button" @click="openGlobalBuildDate" value='Set Build Date'>
        </div>
        <!-- ADD CURB ROW -->
         <div v-show="!state.object.data.actual_ship && !creditCardLock" class="form-inline spacing_btm">
            <select class="form-control" v-model='newCurbTypeID' style="width: 200px;">
                <option v-for='[id, curbType] in cache.curbTypeCache.CURB_TYPES' :value='id' :key='id'>{{ curbType }}</option>
            </select>
            <input class='btn btn-default' @click='addNewCurb' type='button' value='Add New Item'>
        </div>
        <!-- CURBS -->
        <div v-if='curbs.length' >
            <div v-for="(curb, index) in curbs" :key='`${curb.tracker_invoice_id}`'>
                <div class="col-sm-12" :class="(curb.showLineData) ? 'margin_bottom' : ''">
                    <div :class="`line_heading_container toggle_container dropdown ${(!curb.showLineData) ? 'spacing_btm' : ''}`">
                        <span v-if='!curb.showLineData' @click="curb.showLineData = !curb.showLineData" class="pull-right glyphicon glyphicon-triangle-bottom icon_md show_curb_details cdi_link" cdi_action="show_curb_details"></span>
                        <div :class='`cdi_link line_qty ${ curb.cname }`' @click='toggleBarcode(index)' :title='curb.barcode'>
                            {{ curb.line }}
                        </div>
                        <div :class="`dropdown-menu ${(curb.showBarcode) ? 'cdi_show' : 'cdi_hide'}`" >
                            <img :src='`https://chart.googleapis.com/chart?cht=qr&chs=75x75&chld=L|0&chl=${ curb.barcode }`' class="pull-left">
                            {{ curb.barcode }}
                            <div :class="`form-inline ${ curb.rowStyle }`"><label>Points</label> <input type='text' class='curb_pts form-control' @keyup.enter="saveCurbPoints(curb)" v-model='curb.curb_points' style="width: 40px;"></div>
                        </div>
                        <div class="line_heading">
                            <div>{{ curb.custom_disp }}
                                <a target='_new' :href='`/curb/${ curb.curb_id }`'>
                                    {{ curbLineDisplay(curb) }}
                                </a>
                                <span class='inline_block'> {{ curb.tag }}</span>
                                <div v-if="curb.curb_variant_id" class="badge badge-primary"> v{{ curb.curb_variant_id }}</div>
                                <div v-if="curb.conforming_type" class="badge badge-primary"> Conforming {{ curb.conforming_type }}</div>
                                <div v-if="curb.is_oshpd" class="badge badge-alert"> OSHPD </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div class="col-sm-7" v-show='curb.showLineData'>
                    <div class="line_body" v-show="curb.showLineData">
                        <span v-html="curb.desc"></span>
                        <div v-show='curb.rtu_accessories_desc'><i>RTU Accessories:</i> {{ curb.rtu_accessories_desc}}</div>
                    </div>
                </div>
                <div class="col-sm-5" v-show='curb.showLineData'>
                    <div class="line_drawings well well-sm" v-show="curb.showLineData && curb.line_master">
                        <ul class="list-inline line_drawings_list">
                            <ProtectedLink  v-if="curb.standard_spring_configurations.length > 0" :id="curb.tracker_invoice_id" :linkType="'iconText'" :classes="'glyphicon glyphicon-new-window cdi_icon'" :text="'Standard Spring Calculations'" :queryString="queryString" :type="'spring_configuration'" :displayInline="true" />
                            <li v-for='drawing in curb.drawings' :key='drawing.cdi_file_id'>
                                <ProtectedLink :id="`${drawing.cdi_file_id}`" :linkType="'iconText'" :classes="'glyphicon glyphicon-file cdi_icon'" :text="drawing.title" :queryString="queryString" :type="'drawing'" :displayInline="true" />
                               &nbsp; <span v-if='`${drawing.cdi_file_dir}` === "customs"||`${drawing.cdi_file_dir}` ==="internal"'
                                      class="glyphicon glyphicon-remove cdi_link"
                                      style="color: #333333"
                                      @click="$refs.deleteDrawingConfirm.open({ trackerID: curb.tracker_invoice_id, cdiFileID: drawing.cdi_file_id, })"
                                >
                                </span>
                            </li>
                            <li>
                                <a target='_new' :href='`/cdi_file?cdi_reference_id=${ curb.tracker_invoice_id }&enabled_only=0&cdi_file_datetime_from=""&ignore_default=1`'><span class="glyphicon glyphicon-new-window cdi_icon"></span> Past Tracker Drawings</a>
                            </li>
                        </ul>
                        <span v-if='curb.has_dr'>
                    <i v-if='curb.has_dr.drawing_request_id'>Open Drawing Request <span v-if='curb.has_dr.last_clocked_in_employee'> - {{ curb.has_dr.last_clocked_in_employee}}</span></i>
                </span>
                    </div>
                </div>
                <div class="col-sm-12" v-show='curb.showLineData'>
                    <div class="row">
                        <div class="col-sm-9">
                            <div class="btn-group btn-block btn_row">
                                <button class='btn btn-default' v-show="curb.editable && curb.showLineData && !creditCardLock" @click="editCurb(curb)">Edit</button>
                                <button class='btn btn-default' v-show="curb.line_master" @click='estimator(curb)'>Estimator</button>
                                <button class='btn btn-default' v-show="curb.line_master && !curb.has_dr.drawing_request_id" @click="$refs.drawingRequest.open(0, curb.tracker_invoice_id, curb.curb_id)">Draw Request</button>
                                <button class='btn btn-default' v-show="curb.permissions.build_date && curb.line_master" @click='openBuildDate(curb.build_date, curb.tracker_invoice_id)'>Build Date {{ curb.build_date }}</button>
                                <button class='btn btn-default' v-show="curb.permissions.jc && !state.object.data.locked && curb.status.length > 0" @click="openPullModal(curb.tracker_invoice_id)">Pull</button>
                                <button class='btn btn-default' v-show="curb.permissions.jc && !state.object.data.locked && curb.status.length > 0 && curb.permissions.rework" @click="setReworkModal(curb.tracker_invoice_id)">Re-batch</button>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <ul class="list-inline">
                                <li><input type='checkbox' v-model='curb.alt_brace_kit' @change="toggleAltBraceKit(curb.tracker_invoice_id)"> <strong>Alt Brace Kit</strong></li>
                                <li v-show="curb.line_master"><input type='checkbox' :true-value='1' :false-value='0' @change='toggleHold(curb.tracker_invoice_id)' v-model='curb.hold' /> <strong>Hold</strong></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Photos :photos="curb.photos" />
              <div class="itemFromStock" v-show="curb.from_stock">This Curb is From Stock
                <button class='btn btn-default' v-show="curb.from_stock && allowedToDeallocate"
                        @click="$refs.deallocateItemConfirm.open({turretReady: curb.turret_ready ,trackerInvoiceID:curb.tracker_invoice_id,curbID: curb.curb_id, nextTaskID: curb.next_task_id, cdiLocationID: state.object.data.cdi_location_id, trackerID: curb.tracker_id})">Deallocate</button>
              </div>
                <JobCosting
                    ref='jobCosting'
                    @changeView='changeView'
                    v-show='curb.status.length'
                    :status='curb.status'
                    :trackerInvoiceId="curb.tracker_invoice_id"
                    :permission="curb.permissions.jc"
                    :locked="state.object.data.locked"
                />
                <ul v-if='curb.reworks.length'>
                    <li :style="(rework.disabled) ? 'text-decoration: line-through' : ''" v-for='(rework, index) in curb.reworks' :key='index'><b>Re-batch</b> {{ rework.rework_description }}, {{rework.cause_of_error}}, {{rework.items_needed_for_batch}}, {{ rework.rework_notes }} by {{ rework.employee }} @ {{ rework.rework_date }} <span v-show='!rework.disabled' class='glyphicon glyphicon-trash cdi_link' @click='openUndoReworkModal(rework.tracker_rework_id, curb.tracker_invoice_id)'></span></li>
                </ul>
            </div>
            <DrawingRequest :companyID="state.object.data.company_id" @submitted="() => this.$emit('refreshCurbs')" ref='drawingRequest' />
        </div>

        <Modal heading="Build Date" v-if='buildDateModal' @close="closeBuildDate">
            <template v-slot:body>
                <div class="datepicker-spacer">
                    Please enter a new build date:
                    <DatePicker v-model='currentBuildDate' :input-attributes="{class:'form-control'}" />
                </div>
            </template>
            <template v-slot:footer>
                <button class='btn btn-primary' @click='saveBuildDate'>Update</button>
                <button class='btn btn-default' @click='closeBuildDate'>Cancel</button>
            </template>
        </Modal>

        <Modal heading="Order Build Dates" v-if='globalBuildDateModal' @close="closeGlobalBuildDate">
            <template v-slot:body>
                <div class="datepicker-spacer">
                    Please enter a new build date:
                    <DatePicker v-model='currentGlobalBuildDate' :input-attributes="{class:'form-control spacing_btm'}" />
                    <p>Update will take a few seconds to become visible on your screen.</p>
                </div>
            </template>
            <template v-slot:footer>
                <button class='btn btn-primary' @click='saveGlobalBuildDate'>Update</button>
                <button class='btn btn-default' @click='closeGlobalBuildDate'>Cancel</button>
            </template>
        </Modal>

        <Modal heading="Build Date" v-if='buildDateModal' @close="closeBuildDate">
            <template v-slot:body>
                <div class="datepicker-spacer">
                    Please enter a new build date:
                    <DatePicker v-model='currentBuildDate' :input-attributes="{class:'form-control'}" />
                </div>
            </template>
            <template v-slot:footer>
                <button class='btn btn-primary' @click='saveBuildDate'>Update</button>
                <button class='btn btn-default' @click='closeBuildDate'>Cancel</button>
            </template>
        </Modal>

        <Modal heading="Stock" v-if='pullModal' @close='closePullModal'>
            <template v-slot:body>
                Pull from shop?
            </template>
            <template v-slot:footer>
                <button class='btn btn-primary' @click='pullFromShop'>Yes</button>
                <button class='btn btn-default' @click='closePullModal'>No</button>
            </template>
        </Modal>

        <Modal heading="Re-batch Issue" v-if='reworkModal' @close='closeReworkModal'>
            <template v-slot:body>
                <div class='form-group'>
                    <label>Error Type</label>
                    <Typeahead :cdi_type='reworkType' :autoPopulate="true"  :input-attributes="{class: 'form-control'}" :object.sync='reworkSelection' />
<br/>
                    <label>Cause of Error</label>
                    <textarea class='form-control' placeholder='Cause' v-model='causeOfError'></textarea>
                    <br/>
                    <label>Items Needed to Batch</label>
                    <textarea class='form-control' placeholder='Items' v-model='itemsNeededForBatch'></textarea>
                    <br/>
                    <label>Notes</label>
                    <textarea class='form-control' placeholder='Additional Notes' v-model='reworkNotes'></textarea>
<br/>
                    <label>Employee Responsible</label>
                    <Typeahead cdi_type="employee" v-bind:ID.sync="reworkEmployee" />
                </div>
            </template>
            <template v-slot:footer>
                <button class='btn btn-default' @click='submitRework'>Issue</button>
            </template>
        </Modal>

        <Modal heading="Undo Rework" v-if='undoReworkModal' @close='closeUndoReworkModal'>
            <template v-slot:body>
                Was this rework issued incorrectly?
            </template>
            <template v-slot:footer>
                <button class='btn btn-primary' @click='undoRework'>Yes</button>
                <button class='btn btn-default' @click='closeUndoReworkModal'>No</button>
            </template>
        </Modal>

        <Confirm ref="deleteDrawingConfirm" :heading="'Delete Custom Drawing?'" :body="'Would you like to delete the custom drawing?'" @response="deleteDrawing" />
        <Confirm ref="deallocateItemConfirm" :heading="'Deallocate?'" :body="'Would you like to deallocate this item and return it to stock?'" @response="deallocateItem" />
    </div>
</template>

<script>

import curbTypeCache from '@/cache/curbType.cache';
import { store } from '@/store/BusinessObject.store';
import Photos from "@/components/businessObjects/tracker/Photos";
import JobCosting from "@/components/businessObjects/tracker/JobCosting";
import DrawingRequest from "@/components/businessObjects/utils/DrawingRequest";
import Modal from "@/components/utils/Modal";
import DatePicker from "@/components/utils/DatePicker";
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import ProtectedLink from "@/components/utils/ProtectedLink";
import helpers from "@/store/helpers";
import Confirm from "@/components/utils/Confirm";
import appStore from "@/store/App.store";

export default {
    name: 'Items',
    components: {Confirm, Typeahead, DatePicker, JobCosting, Photos, DrawingRequest, Modal, ProtectedLink},
    props: ['curbs', 'creditCardLock'],
    data() {
        return {
            state: store.state,
            cache: {
                curbTypeCache,
            },
            activeInvoiceCurb: 0,
            newCurbTypeID: 1,
            activeBuildDateCurb: 0,
            currentBuildDate: '00/00/0000',
            currentGlobalBuildDate: '00/00/0000',
            buildDateModal: false,
            globalBuildDateModal: false,
            pullModal: false,
            activePullCurb: 0,
            activeReworkCurb: 0,
            reworkType: 'tracker_rework_opts',
            reworkModal: false,
            reworkSelection: 0,
            reworkNotes: '',
            causeOfError:'',
            itemsNeededForBatch: '',
            reworkEmployee: 0,
            undoReworkModal: false,
            activeReworkUndoId: 0,
            activeReworkUndoCurbId: 0
        }
    },
    computed: {
      allowedToDeallocate(){
        return this.$appStore.hasRole(this.$cdiVars.CDI_ROLE_MANAGER, this.$cdiVars.CDI_ROLE_SHOP_SUP)
      },
        currentView: function() {
            return this.state.currentView;
        },
        queryString: function() {
            const params = helpers.getQSString({ 'params' : this.companyId});
            return `genid=${Math.floor(Math.random()*101)}&${params}`;
        }
    },
    methods: {
      deallocateItem: function(data){
        store.apiGeneric(`tracker_curb/deallocate_item/${data.trackerInvoiceID}`, {
        'params[cdi_location_id]': data.cdiLocationID}).then( () => { this.$emit('refreshCurbs')});
      },
        deleteDrawing: function(data) {
            if (data) {
                store.apiGeneric(`tracker_curb/delete_custom_drawing/${data.trackerID}`, { params: data.cdiFileID }).then( () => { this.$emit('refreshCurbs')}); }},
        addNewCurb: function() {
            if(this.creditCardLock){
                appStore.errorBox("Cannot change price for a credit card order.");
            }else {
                const curb = {curb_type_id: this.newCurbTypeID}
                this.changeView({view: 'add-item', curb: curb});
            }
        },
        editCurb: function(curb) {
            if(this.creditCardLock){
                appStore.errorBox("Cannot change price for a credit card order.");
            }else {
                this.changeView({view: 'add-item', curbTypeID: curb.curb_type_id, curb: curb})
            }
        },
        estimator: function(curb) {
            this.activeInvoiceCurb = curb;
            this.changeView({view: 'estimator', data: curb});
            this.activeInvoiceCurb = 0
        },
        saveBuildDate: function() {
            store.customApi(`tracker_curb/save_build_date/${this.activeBuildDateCurb}`, {'params[build_date]': this.currentBuildDate}).then( () => { this.$emit('refreshCurbs') } );
            this.closeBuildDate();
        },
        toggleBarcode: function(index) {
            this.curbs[index].showBarcode = !this.curbs[index].showBarcode;
        },
        openBuildDate: function(buildDate, trackerInvoiceId) {
            this.activeBuildDateCurb = trackerInvoiceId;
            this.buildDateModal = true;
            this.currentBuildDate = buildDate;
        },
        openGlobalBuildDate: function() {
            this.globalBuildDateModal = true;
        },
        closeGlobalBuildDate: function() {
            this.globalBuildDateModal = false;
            this.currentGlobalBuildDate = '00/00/0000';
        },
        saveGlobalBuildDate: function() {
            store.customApi(`tracker/save_build_date/${this.state.object.data.tracker_id}`, {'params[build_date]': this.currentGlobalBuildDate}).then( () => { this.$emit('refreshCurbs') } );
            this.closeGlobalBuildDate();
        },
        closeBuildDate: function() {
            this.activeBuildDateCurb = 0;
            this.buildDateModal = false;
            this.currentBuildDate = '00/00/0000';
        },
        openPullModal: function(trackerInvoiceId) {
            this.activePullCurb = trackerInvoiceId;
            this.pullModal = true;
        },
        closePullModal: function() {
            this.activePullCurb = 0;
            this.pullModal = false;
        },
        pullFromShop: function() {
            store.customApi(`tracker_curb/pull_from_shop/${this.activePullCurb}`, {}).then( () => { this.$emit('refreshCurbs')});
            this.closePullModal();
        },
        saveCurbPoints: function(curb) {
            store.customApi(`tracker_curb/save_curb_points/${curb.tracker_invoice_id}`, {'params': curb.curb_points});
        },
        toggleAltBraceKit: function(trackerInvoiceId) {
            store.customApi(`tracker_curb/toggle_alt_brace_kit/${trackerInvoiceId}`, {}).then( () => {this.$emit('refreshCurbs')});
        },
        toggleHold: function(trackerInvoiceId) {
            store.customApi(`tracker_curb/toggle_hold/${ trackerInvoiceId }`).then( () => { this.$emit('refreshCurbs') });
        },
        changeView: function(data) {
            this.$emit('changeView', data);
        },
        setReworkModal: function(trackerInvoiceId) {
            store.customApi(`tracker_curb/issue_rework_dlg/${trackerInvoiceId}`, {}).then( (data) => {
                this.reworkType = (data.eng_rework) ? 'tracker_eng_rework_opts' : 'tracker_rework_opts'
            });
            this.activeReworkCurb = trackerInvoiceId;
            this.reworkModal = true;
        },
        closeReworkModal: function() {
            this.reworkType = '';
            this.reworkModal = false;
            this.activeReworkCurb = 0;
            this.reworkNotes = '';
            this.causeOfError = '';
            this.itemsNeededForBatch = '';
            this.reworkSelection = 0
        },
        submitRework: function() {
            // Make submission
            if(this.reworkNotes == 0 || this.reworkSelection == 0 || this.reworkEmployee == 0 || this.causeOfError == 0 || this.itemsNeededForBatch == 0){
                this.$appStore.errorBox('Must fill out all fields.');
            }else {
                store.customApi(`tracker_curb/issue_rework/${this.activeReworkCurb}`, {
                    'params[rework_description]': this.reworkSelection.label,
                    'params[rework_notes]': this.reworkNotes,
                    'params[cause_of_error]': this.causeOfError,
                    'params[items_needed_for_batch]': this.itemsNeededForBatch,
                    'params[rework_employee]': this.reworkEmployee,
                    'params[engineering]': this.reworkType
                }).then(() => {
                    this.$emit('refreshCurbs');
                });
                this.closeReworkModal();
            }
        },
        openUndoReworkModal: function(reworkId, curbId) {
            this.undoReworkModal = true;
            this.activeReworkUndoId = reworkId;
            this.activeReworkUndoCurbId = curbId;
        },
        closeUndoReworkModal: function() {
            this.undoReworkModal = false;
            this.activeReworkUndoId = 0;
            this.activeReworkUndoCurbId = 0;
        },
        undoRework: function() {
            store.customApi(`tracker_curb/undo_rework/${ this.activeReworkUndoCurbId }`, {
                'params[tracker_rework_id]': this.activeReworkUndoId
            }).then( () => {
                this.$emit('refreshCurbs');
            })
            this.closeUndoReworkModal();
        },
        curbLineDisplay: function(curb) {
            let tf = '';
            if (curb.turret_ready) {
                tf = 'T';
                if (curb.not_kit) {
                    tf = tf.concat('F');
                }
            }
            
            return `${tf} ${curb.nomenclature}`;
        },
        open: function(value) {
            this.show = true;
            this.value = value;
        },
        close: function (value) {
            this.$emit('response', value);
            this.show = false;
            this.value = false;
        }
    },
    watch: {
        currentView: function() {
            if (this.state.currentView === 'item') {
                // Refresh all child jobcosting data that is "open" in the view
                if (this.$refs.jobCosting) {
                    for (let i = 0; i < this.$refs.jobCosting.length; i++) {
                        if (this.$refs.jobCosting[i].$data.jobCosting) {
                            this.$refs.jobCosting[i].getJobCosting();
                        }
                    }
                }
            }
        }
    }
}

</script>

<style scoped>
.badge-alert{
  color: white;
  background-color: #FF0000;
}
.btn_row {
    margin-left: 15px;
}
.datepicker-spacer {
    min-height: 300px;
}
.itemFromStock{
  text-align: right;
}
</style>