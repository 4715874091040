<template>
    <div>
        <div class="row">
            <RTUTag :val.sync="editCurb.tag" />
        </div>
        <div class="row">
            <Spacing :val.sync="editCurb.spacing" />
            <Diameter :val.sync="editCurb.diameter" />
            <Frame :val.sync="editCurb.frame" />
        </div>
        <div class="row">
            <Model
                :label="'New Model'"
                :val="editCurb.new_model"
                @update="(newModel) => {
                    editCurb.cdi_part_id_new = newModel.cdi_part_id;
                    editCurb.new_model_id = newModel.unit_model_id;
                    editCurb.new_model = newModel.label;                
                }"
            />
        </div>
        <div class="row">
            <Applied :val.sync="editCurb.applied" />
            <Sectional :val.sync="editCurb.sectional" />
        </div>

        <div class="spacing_top">
            <input class="btn btn-default" type="button" value="Save" @click="save">
            <input class="btn btn-default" type="button" value="Cancel" @click="cancel">
        </div>
    </div>
</template>

<script>
import RTUTag from './fields/RTUTag';
import Applied from "./fields/Applied";
import Sectional from "./fields/Sectional";
import Spacing from "./fields/Spacing";
import Diameter from "./fields/Diameter";
import Frame from "./fields/Frame";
import Model from "./fields/Model";

export default {
    name: "BurglarBar",
    components: {
        RTUTag,
        Applied,
        Sectional,
        Spacing,
        Diameter,
        Frame,
        Model,
    },
    props: ["curb"],
    data() {
        return {
            editCurb: {
                tracker_invoice_id: 0,
                tag: '',
                spacing: 6,
                diameter: 0,
                frame: 0,
                cdi_part_id_new: 0,
                new_model_id: 0,
                new_model: '',
                applied: 0,
                sectional: 0,
            },
        }
    },
    methods: {
        save: function() {
            if (!this.errors()) {
                this.$emit('save', this.editCurb);
            }
        },
        cancel: function() {
            this.$emit('cancel');
        },
        errors: function() {
            let errors = [];
            if (!parseInt(this.editCurb.new_model_id) && !parseInt(this.editCurb.cdi_part_id_new)) {
                errors.push('Need to select New Model');
            }
            if (errors.length) {
                this.$appStore.errorBox(errors.join("\n"));

                return true;
            }

            return false;
        },
        load: function() {
            for (const field in this.editCurb) {
                this.editCurb[field] = this.curb[field];
            }
        },
    },
    created() {
        if (this.curb.tracker_invoice_id) {
            this.load();
        }
    }
}
</script>

<style scoped>

</style>