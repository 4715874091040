<template>
  <div class="col-xs-6 form-group">
    <label>{{ label }}</label>
    <select v-model="curb_depth_id" class="form-control" @change="$emit('update:val', curb_depth_id)">
      <option v-for="curb in curb_depths" :key="curb[0]" :value="curb[0]">{{ curb[1] }}</option>
    </select>
  </div>
</template>

<script>
import curbTypeCache from  '@/cache/curbType.cache.js';
export default {
  name: "CurbDepth",
  props: ['label', 'val', 'curbTypeID'],
  data() {
    return {
      curb_depth_id: 0,
      cache: {
        curbTypeCache
      },
    }
  },
  components: {},
  computed: {
    curb_depths: function() {
      return this.cache.curbTypeCache.CURB_DEPTH;
    },
  },
  mounted() {
    this.$refs.model.$data.object = this.val;
  },
  created() {
    this.curb_depth_id = this.val;
  },
}
</script>

<style scoped>

</style>